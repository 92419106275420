import Vue from 'vue'
import Router from 'vue-router'
import store from "./store";
import Login from './views/login.vue'
import index from './components/index.vue'


import Dashboard from "./pages/dashboard";
import ProductCode from "./pages/admin-code"
import Purchases from "./pages/purchases";

// admin
import Admin from "./pages/admin";
import AdminIndex from "./pages/admin-index";
import Users from "./pages/user/index";
import CreateUser from "./pages/user/add";
import EditUser from "./pages/user/edit";
import Reconcile from "./pages/user/reconcile";

// transaction
import TransactionIndex from "./pages/transaction/index"
import TransactionStats from "./pages/transaction/stats"
import TransactionView from "./pages/transaction/view"

// deposit
import Deposit from "./pages/deposit/index"
import DepositStats from "./pages/deposit/stats"
import DepositView from "./pages/deposit/view"

// customers
import Customers from "./pages/customers/index"
import CustomersAll from "./pages/customers/view"
import CustomerSearch from "./pages/customers/search"
import SMS from "./pages/customers/sms"
import SendSMS from "./pages/customers/send"
import bonus from "./pages/customers/bonus"

// prediction
import Prediction from "./pages/prediction/index";
import AddPrediction from "./pages/prediction/add";
import EditPrediction from "./pages/prediction/edit";
import Jackpot from "./pages/prediction/jackpot";
import AddJackpot from "./pages/prediction/addJackpot";
import PredictionSponsored from "./pages/prediction/sponsored";
import PredictionTag from "./pages/prediction/tag";
import PredictionUpdate from "./pages/prediction/update";

// News
import NewsCategory from "@/pages/news/category"
import NewsTag from "@/pages/news/tag"
import Ads from "@/pages/news/ads.vue"
import News from "@/pages/news/index"
import AddNews from "@/pages/news/add"
import EditNews from "@/pages/news/edit"
import Page from "@/pages/news/pages"

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        //{ path: '', name: 'home', component: Home },
        {
            path: '/',
            name: 'login',
            component: Login,
            meta: { guest: true }
        },
        {
            path: '/app',
            component: index,
            children: [
                { path: '', name: 'dashboard', component: Dashboard, meta: { name: 'Dashboard' } },
                {
                    path: 'purchases',
                    name: 'purchases',
                    component: Purchases,
                    meta: { name: 'Purchases' }
                },
                { path: 'admin', component: AdminIndex, children: [
                        { path: '', name: 'admin', component: Users, meta: { name: 'Admin' } },
                        { path: 'users', name: 'users', component: Users, meta: { name: 'Admin > Users' } },
                        { path: 'users/add', name: 'addUser', component: CreateUser, meta: { name: 'Admin > Create User' } },
                        { path: 'users/edit', name: 'editUser', component: EditUser, meta: { name: 'Admin > Edit User' } },
                        { path: 'users/reconcile', name: 'tag.vue', component: Reconcile, meta: { name: 'Admin > Reconcile' } },
                        { path: 'product-code', name: 'product-code', component: ProductCode, meta: { name: 'Admin > Product Code' } }
                    ]
                },
                { path: 'transaction', component: TransactionIndex, children: [
                        { path: '', name: 'transaction', component: TransactionStats, meta: { name: 'Transaction' } },
                        { path: 'stats', name: 'transactionStats', component: TransactionStats, meta: { name: 'Transaction > Stats' } },
                        { path: 'view', name: 'transactionView', component: TransactionView, meta: { name: 'Transaction > View' } },
                    ]
                },
                { path: 'deposit', component: Deposit, children: [
                        { path: '', name: 'deposit', component: DepositStats, meta: { name: 'Deposit' } },
                        { path: 'stats', name: 'depositStats', component: DepositStats, meta: { name: 'Deposit > Stats' } },
                        { path: 'view', name: 'depositView', component: DepositView, meta: { name: 'Deposit > View' } },
                    ]
                },
                { path: 'customers', component: Customers, children: [
                        { path: '', name: 'customers', component: Customers, meta: { name: 'Customers' } },
                        { path: 'view', name: 'customersAll', component: CustomersAll, meta: { name: 'Customers > All' } },
                        { path: 'search', name: 'CustomerSearch', component: CustomerSearch, meta: { name: 'Customers > Search' } },
                        { path: 'sms', name: 'SMS', component: SMS, meta: { name: 'Customers > SMS' } },
                        { path: 'sms/send', name: 'SendSMS', component: SendSMS, meta: { name: 'Customers > Send SMS' } },
                        { path: 'bonus', name: 'bonus', component: bonus, meta: { name: 'Customers > Allocate Bonus' } },
                    ]
                },
                { path: 'prediction', component: AdminIndex, children: [
                        { path: 'view', name: 'prediction', component: Prediction, meta: { name: 'Prediction' } },
                        { path: 'add', name: 'AddPrediction', component: AddPrediction, meta: { name: 'Prediction > Add' } },
                        { path: 'edit', name: 'EditPrediction', component: EditPrediction, meta: { name: 'Prediction > Edit' } },
                        { path: 'jackpot', name: 'jackpot', component: Jackpot, meta: { name: 'Jackpot' } },
                        { path: 'addJackpot', name: 'AddJackpot', component: AddJackpot, meta: { name: 'Jackpot > Add' } },
                        { path: 'tag', name: 'predictionTag', component: PredictionTag, meta: { name: 'Prediction > Tag' } },
                        { path: 'sponsored', name: 'PredictionSponsored', component: PredictionSponsored, meta: { name: 'Prediction > Sponsored' } },
                        { path: 'update', name: 'PredictionUpdate', component: PredictionUpdate, meta: { name: 'Prediction > Update' } },
                    ]
                },
                { path: 'news', component: AdminIndex, children: [
                        { path: '', name: 'news', component: News, meta: { name: 'View Article' } },
                        { path: 'add', name: 'addNews', component: AddNews, meta: { name: 'Add Article' } },
                        { path: 'edit', name: 'editNews', component: EditNews, meta: { name: 'Edit Article' } },
                        { path: 'category', name: 'category', component: NewsCategory, meta: { name: 'News Category' } },
                        { path: 'tag', name: 'tag', component: NewsTag, meta: { name: 'News Tag' } },
                        { path: 'page', name: 'page', component: Page, meta: { name: 'Page Settings' } },
                        { path: 'ads', name: 'ads', component: Ads, meta: { name: 'Page Ads' } },
                    ]
                },
        ] },
    ]
})
/*
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            next("/dashboard");
            return;
        }
        next();
    } else {
        next();
    }
});
*/

export default router

<template>
  <div>
    <div class="l-container-content bg-white">
      <div class="row">
        <div class="col-6">
          <h3>News Tags</h3>
        </div>
        <div class="col-6 text-right">
          <div class="button primary" @click.prevent="toggle">Add Tag</div>
        </div>
      </div>
      <div class="row">
        <loader v-show="loading"/>
        <vuetable ref="vuetable"
                  :api-mode="false"
                  :fields="fields"
                  :per-page="perPage"
                  track-by="id"
                  :data-manager="dataManager"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
                  @vuetable:loading="onLoading"
                  @vuetable:loaded="onLoaded"
        >
          <template slot="actions" slot-scope="props">
            <button class="small ui button blue" @click="editRow(props.rowData)">Edit</button>
          </template>
          <template slot="status" slot-scope="props">
            <button v-if="parseInt(props.rowData.status) === 1" class="small ui button green">Active</button>
            <button v-else class="small ui button red">Inactive</button>
          </template>
        </vuetable>
        <div style="padding-top:10px">
          <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
        </div>
      </div>
    </div>

    <!--  Modal | Add Ads -->
    <sui-modal v-model="open">
      <sui-modal-header>Create a new Ad</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <sui-form>
            <sui-form-field>
              <label>Name</label>
              <input placeholder="Name" type="text" v-model="form.name"/>
            </sui-form-field>
            <sui-form-field>
              <label>Select Type</label>
              <sui-dropdown
                  placeholder="Type"
                  selection
                  search
                  :options="types"
                  v-model="form.type"
              />
            </sui-form-field>
            <sui-form-field v-if="form.type === 'image'">
              <label>Image</label>
              <input type="file" id="image" name="image" placeholder="Enter Image" accept="image/*" required>
            </sui-form-field>
            <sui-form-field v-else>
              <label>HTML Code</label>
              <textarea placeholder="HTML Code" v-model="form.html" cols="3"></textarea>
            </sui-form-field>
            <sui-form-field>
              <label>Link</label>
              <input placeholder="Link" v-model="form.link"/>
            </sui-form-field>
            <sui-form-field>
              <label>Priority</label>
              <input placeholder="Priority" type="number" v-model="form.priority"/>
            </sui-form-field>
            <sui-form-fields fields="two">
              <sui-form-field>
                <label>Start Date</label>
                <input placeholder="Start Date" v-model="form.start_date" type="date"/>
              </sui-form-field>
              <sui-form-field>
                <label>End Date</label>
                <input placeholder="Start Date" v-model="form.end_date" type="date"/>
              </sui-form-field>
            </sui-form-fields>
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click.native="resetForm"> Close </sui-button>
        <sui-button color="blue" @click.native="createAd"> Add </sui-button>
      </sui-modal-actions>
    </sui-modal>

    <!--  Modal | Edit Category -->
    <sui-modal v-model="edit">
      <sui-modal-header>News Tag</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <sui-form>
            <sui-form-field>
              <label>Name</label>
              <input placeholder="Name" type="text" v-model="form.name"/>
            </sui-form-field>
            <sui-form-field>
              <label>Priority</label>
              <input placeholder="Priority" type="number" v-model="form.priority"/>
            </sui-form-field>
            <sui-form-field>
              <label>Page Title</label>
              <input placeholder="Page Title" v-model="form.page_title"/>
            </sui-form-field>
            <sui-form-field>
              <label>Page Description</label>
              <input placeholder="Page Description" v-model="form.page_desc"/>
            </sui-form-field>
            <sui-form-field>
              <label>Page Keywords</label>
              <textarea placeholder="Page Keywords" v-model="form.page_keywords" rows="4"></textarea>
            </sui-form-field>
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click.native="resetForm"> Close </sui-button>
        <sui-button color="blue" @click.native="updateNewsTag"> Update </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions} from "vuex";
import _ from "lodash";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "approvals",

  data() {
    return {
      open: false,
      edit: false,
      loading: true,
      fields: [
        {
          name: "name",
          title: "Name",
          sortField: "name",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "priority",
          title: "Priority",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
        {
          name: "status",
          title: "Status",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
        {
          name: "actions",
          title: "Action",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
      ],
      sortOrder: [
        {
          field: "created_at",
          direction: "desc"
        }
      ],
      moreParams: {
        start: "",
        filter: "",
        end: "",
        limit: 1000
      },
      perPage: 10,
      data: [],
      form: {
        id: null,
        name: null,
        html: null,
        link: null,
        type: null,
        start_date: null,
        end_date: null,
        priority: null
      },
      types: [{text: "HTML", value: "html"}, {text: "Image", value: "image"}]
    };
  },

  mounted() {

    var vm = this;

    vm.setAds()
  },

  watch: {
    data(newVal, oldVal) {
      this.reload()
    }
  },

  methods: {
    ...mapActions(["getAdds", "addAd", "editNewsTag"]),
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.refresh();
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },
    async toggle() {
      this.open = !this.open;
    },
    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
    },

    async setAds() {
      let response = await this.getAdds(this.moreParams)
      this.data = response.message
    },
    async createAd() {
      let app = this

      let payload = this.form

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this adds a new ad!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.addAd(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 201) {
              app.$swal.fire('Added!', result.value.message, 'success')
              app.resetForm()
              app.setTags()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },
    async updateNewsTag() {
      let app = this

      let payload = this.form

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this updates this tag!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.editNewsTag(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Updated!', result.value.message, 'success')
              app.resetForm()
              app.setTags()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },
    async editRow(data){
      this.form.id = data.id
      this.form.name = data.name
      this.form.priority = data.priority
      this.form.page_title = data.page_title
      this.form.page_desc = data.page_desc
      this.form.page_keywords = data.page_keywords
      this.edit = true
    },
    resetForm() {
      this.form.name = null
      this.form.priority = null
      this.form.html = null
      this.form.link = null
      this.form.start_date = null
      this.form.end_date = null
      this.form.priority = null
      this.open = false
      this.edit = false
    }
  }
};
</script>
<style scoped>
td.vuetable-slot {
  overflow: visible !important;
}
.l-container-content{
  padding: 1.5em;
}
</style>

<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col-6">
        <h3>All Messages</h3>
      </div>
      <div class="col-6 text-right">
        <router-link :to="{name: 'SendSMS'}" class="button primary">Send SMS</router-link>
      </div>
    </div>
    <div class="row">
      <loader v-show="loading"/>
      <vuetable ref="vuetable"
                :api-url="url"
                :fields="fields"
                :per-page="perPage"
                track-by="id"
                :append-params="moreParams"
                :httpOptions="httpOptions"
                data-path="data.data.data"
                pagination-path="data.data"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded"
      >
        <template slot="time" slot-scope="props">
          {{ moment(props.rowData.start_date).format('ll') }} - {{ moment(props.rowData.end_date).format('ll') }}
        </template>
        <template slot="status" slot-scope="props">
          <sui-button v-if="parseInt(props.rowData.status) === 1" size="mini" color="green" content="Active" />
          <sui-button v-else-if="parseInt(props.rowData.status) === 3" size="mini" color="red" content="Deactivated" />
          <sui-button v-else size="mini" color="purple" content="Inactive" />
        </template>
        <template slot="actions" slot-scope="props">
          <sui-dropdown class="icon" icon="ellipsis horizontal" pointing="top right" direction="downward">
            <sui-dropdown-menu>
              <sui-dropdown-item @click.prevent="view(props.rowData.id, props.rowData.settings_id)">View Report</sui-dropdown-item>
            </sui-dropdown-menu>
          </sui-dropdown>
        </template>
      </vuetable>
      <div style="padding-top:10px">
        <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions} from "vuex";
import $ from "jquery";
import moment from "moment";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "approvals",

  data() {
    return {
      senderId: 1,
      saveTemplate: false,
      wordCount: 0,
      pageCount: 0,
      schedule: null,
      template: '',
      templateMode: "none",
      templates: [],
      time: '',
      senderIds: [],
      text: '',
      /* vuetable */
      loading: true,
      fields: [
        {
          name: "message",
          title: "Message",
          titleClass: "",
          dataClass: "",
        },
        {
          name: "contacts",
          title: "Contacts",
          titleClass: "",
          dataClass: "",
        },
        {
          name: "type",
          title: "Type",
          titleClass: "",
          dataClass: "",
        },
        {
          name: "created",
          title: "Created",
          formatter (value) {
            return moment(value).format('lll');
          },
        }
      ],
      moreParams: {
        start: "",
        end: "",
      },
      url: this.$store.state.auth.api + 'view/bulk',
      httpOptions: {
        headers: {
          'X-Requested-With': "XMLHttpRequest",
          'X-App-Key': this.$store.getters.StateAppKey,
          'X-Authorization-Key': this.$store.getters.StateAuthKey,
          'X-Token-Key': this.$store.getters.StateTokenKey,
        }
      },
      perPage: 10,
      moment: moment
    };
  },
  watch: {

    text: function (val,oldVal){
      if(val !== oldVal) {
        var count = val.length;
        // Display it as output
        this.wordCount = count;

        var page = count/160;
        this.pageCount = Math.ceil(page);
      }
    },
  },

  methods: {
    ...mapActions(["sendSms"]),
    /* vuetable */
    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      console.log('paginationData: ' + paginationData.total)
      if (paginationData.total < 1){
        this.newSurvey = true;
      }
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onLoading() {
      this.loading = true;
    },
    onLoaded() {
      this.loading = false;
    },

    async submit() {
      let app = this
      let isScheduled = '0'
      if (this.schedule){
        isScheduled = '1'
      }
      const payload = new FormData();
      payload.append('message', this.text)
      payload.append('isScheduled', isScheduled)
      payload.append('time', this.time)
      payload.append('image', $('#image')[0].files[0])

      if( document.getElementById("image").files.length === 0 ){
        app.$swal.fire('Missing!', "File is required", 'error')
        return;
      }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this sends this sms!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, send!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.sendSms(payload)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.$swal.fire('Sent!', result.value.message, 'success')
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },
    resetForm(){
      this.text = ''
      this.time = ''
    },
  }
};
</script>
<style scoped>
td.vuetable-slot {
  overflow: visible !important;
}
.l-container-content{
  padding: 1.5em;
}
</style>

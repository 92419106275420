<template>
  <div class="l-container-content bg-white">
    <sui-segment>
      <div class="row">
        <div class="col-4">
          <h2 is="sui-header">Tips Deposit ( {{ name }} )</h2>
        </div>
        <div class="col-8 text-right">
          <sui-button-group size="mini" class="right aligned">
            <sui-button content="Daily" @click="daily"/>
            <sui-button content="Weekly" @click="weekly"/>
            <sui-button content="Monthly" @click="monthly"/>
          </sui-button-group>
        </div>
      </div>
      <sui-divider />
      <apexchart :key="key" type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </sui-segment>
  </div>
</template>
<script>
import accounting from "accounting";
import {mapActions} from "vuex";

export default {
  components: {

  },
  name: "approvals",

  data() {
    return {
      series: [],
      label: [],
      data: [],
      chartOptions: {},
      moreParams: {
        graph: 'daily'
      },
      name: 'Daily',
      key: '1'
    };
  },

  mounted() {

    var vm = this;
    vm.setTransactions()
  },

  watch: {

  },

  methods: {
    ...mapActions(["getDeposit"]),

    async setTransactions() {
      let response = await this.getDeposit(this.moreParams)
      let res = response.message.stats
      this.data = res.amount
      this.label = res.label
      this.initChart()
      this.key = Math.floor(Math.random() * 1000)
    },
    daily: async function () {
      await this.setTransactions()
      this.name = 'Daily'
      this.moreParams.graph = 'daily'
    },
    weekly: async function () {
      await this.setTransactions()
      this.name = 'Weekly'
      this.moreParams.graph = 'weekly'
    },
    monthly: async function () {
      await this.setTransactions()
      this.name = 'Monthly'
      this.moreParams.graph = 'monthly'
    },

    initChart(){
      this.series = [{
        name: 'Deposit',
        data: this.data
      }]
      this.chartOptions = {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.label,
        },
        yaxis: {
          title: {
            text: 'KES'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "KES " + accounting.formatNumber(val, 2)
            }
          }
        }
      }
    }

  }
};
</script>
<style scoped>

</style>

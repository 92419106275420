<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col">
        <div class="input wide">
          <i class="fa fa-search"></i>
          <input type="text" placeholder="Search..." v-model="filter">

        </div>

      </div>
    </div>
    <div class="row">
      <loader v-show="loading"/>
      <vuetable
          ref="vuetable"
          :api-url="url"
          :fields="columns"
          pagination-path=""
          :sort-order="sortOrder"
          track-by="id"
          :append-params="moreParams"
          :per-page="10"
          :httpOptions="httpOptions"
          @vuetable:pagination-data="onPaginationData"
          @vuetable:loading="onLoading"
          @vuetable:loaded="onLoaded"
      >
        <template slot="status" slot-scope="props" class="text-center">
          <div class="table-button-container">
            <button v-if="parseInt(props.rowData.status) === 1" class="small ui button green">Purchased</button>
            <button v-else class="small ui button red">Not Purchased</button>
          </div>
        </template>
      </vuetable>
      <div class="vuetable-pagination ui basic segment grid">
        <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

        <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import store from "../store";
import {mapActions} from "vuex";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "product-code",

  data() {
    return {
      url:store.state.auth.api + "inventory/code/all",
      file: store.state.auth.api + 'files/',
      httpOptions: { headers: { AUTH: store.state.auth.token } },
      loading: true,
      loadRecord: false,
      selected_data: {},
      columns: [
        {
          name: "code",
          title: '<span class="orange glyphicon glyphicon-user"></span> Code',
          sortField: "code",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },

        {
          name: "inventory_name",
          title: "Inventory Name",
          sortField: "inventory_name",
          titleClass: "",
          dataClass: ""
        },
        'status',
        {
          name: "date_purchased",
          title: "Date Purchased",
          sortField: "date_purchased",
        },
        {
          name: "updated",
          title: "Date Created",
          sortField: "updated",
          callback: "createdAt"
        }
      ],
      range: "",
      filter:'',
      loadOnStart: true,
      sortOrder: [
        {
          field: "created",
          direction: "desc"
        }
      ],
      is_partial_resulting: false,

      moreParams: {
        filter: "",
      }
    };
  },

  mounted() {
      
    var vm = this;
    // if (vm.$cookies.get("role_id") != 4) {
    //   vm.$router.push({ name: "dashboard" });
    // }

    var start = moment().subtract(1, 'year')
    var end = moment();

    function cb(start, end) {
      //vm.dateFilter(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
      $('input[name="dates"]').val(
        start.format("D MMMM, YYYY") + " - " + end.format("D MMMM, YYYY")
      );
    }

    $('input[name="dates"]').daterangepicker(
      {
        startDate: start,
        endDate: end,
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment()
              .subtract(1, "month")
              .startOf("month"),
            moment()
              .subtract(1, "month")
              .endOf("month")
          ]
        }
      },
      cb
    );

    cb(start, end);
  },

  watch: {
    range() {
      var start = this.range[0];
      var end = this.range[1];
      start = moment(start).format("YYYY-MM-DD");
      end = moment(end).format("YYYY-MM-DD");
      this.moreParams.start = start;
      this.moreParams.end = end;
      this.$refs.vuetable.refresh();
    },
    filter: function (val,oldVal) {

      if(val !== oldVal && val.length >= 2) {
        console.log('search: '+val)
        this.searchRecord(val)
      }
    }
  },

  methods: {
    ...mapActions(["fillInventory", "DeleteInventory"]),
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.refresh();

      console.log("start date filter", start);
      console.log("end date filter ", end);
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },

    searchRecord:function(val) {
      this.loadRecord = true;
      this.moreParams.filter = val
      this.reload()
    },

    reload(){
      this.$refs.vuetable.refresh();
    },

    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      console.log("pagination:", pagination);
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },

    onPaginationData(paginationData) {
      this.$refs.paginationInfo.setPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(paginationData);
    },

    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
      $(".ui.dropdown").dropdown();
    },
  }
};
</script>
<style scoped>
td.vuetable-slot {
  overflow: visible !important;
}
.l-container-content{
  padding: 1.5em;
}
</style>
<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col-6">
        <h3>Profile List</h3>
      </div>
    </div>
    <div class="row">
      <loader v-show="loading"/>
      <vuetable ref="vuetable"
                :api-mode="false"
                :fields="fields"
                :per-page="perPage"
                track-by="profile_id"
                :data-manager="dataManager"
                pagination-path="pagination"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded"
      >
      </vuetable>
      <div style="padding-top:10px">
        <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import store from "../../store";
import {mapActions} from "vuex";
import axios from "axios";
import _ from "lodash";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "approvals",

  data() {
    return {
      loading: true,
      fields: [
        {
          name: "kyc_name",
          title: "Full Names",
          sortField: "kyc_name",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "mobile",
          title: "Phone",
          sortField: "mobile",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "email",
          title: "Email",
          sortField: "email",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "network",
          title: "Network",
          sortField: "network",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "wallet_balance",
          title: "Balance",
          sortField: "wallet_balance",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "total_deposits",
          title: "Total Deposit",
          sortField: "total_deposits",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "success_attempts",
          title: "Success Attempts",
          sortField: "success_attempts",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "failed_attempts",
          title: "Failed Attempts",
          sortField: "failed_attempts",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "total_referrals",
          title: "Referrals",
          sortField: "total_referrals",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "last_login_date",
          title: "Last Login",
          sortField: "last_login_date",
          callback: "createdAt"
        },
        {
          name: "created_at",
          title: "Date",
          sortField: "created_at",
          callback: "createdAt"
        },
      ],
      sortOrder: [
        {
          field: "created_at",
          direction: "desc"
        }
      ],

      moreParams: {
        start: "",
        filter: "",
        end: ""
      },
      perPage: 10,
      data: []
    };
  },

  mounted() {

    var vm = this;

    vm.setProfiles()
  },

  watch: {
    data(newVal, oldVal) {
      this.reload()
    }
  },

  methods: {
    ...mapActions(["getProfiles"]),
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.refresh();

      console.log("start date filter", start);
      console.log("end date filter ", end);
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },

    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
    },

    async setProfiles() {
      let response = await this.getProfiles()
      this.data = response.message
    }
  }
};
</script>
<style scoped>
td.vuetable-slot {
  overflow: visible !important;
}
.l-container-content{
  padding: 1.5em;
}
</style>

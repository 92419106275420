<template>
  <div class="l-container-content bg-white">
    <div class="row p-top">
      <div class="col">
        <div class="input wide">
          <i class="fa fa-search"></i>
          <input v-on:keyup.enter="searchRecord" type="text" placeholder="Search..." v-model="search_match">

        </div>

      </div>
      <div class="col" v-show="!match">
        <p>No data found for {{ search_match }}</p>
      </div>
    </div>
    <div v-show="loadRecord" class="row">
      <div class="card bg-white">
        <div class="row vertical-middle">
          <div class="col-6 border-left padding-left-md">
            <table class="table clean">
              <tbody>
              <tr>
                <td><b>Phone No</b></td>
                <td class="text-right">{{ search_match }}</td>
              </tr>
              <tr>
                <td><b>Full Names</b></td>
                <td class="text-right">{{ profile.kyc_name }}</td>
              </tr>
              <tr>
                <td><b>Successful/Failed Login Attempts</b></td>
                <td class="text-right">{{ profile.success_attempts }} / {{ profile.failed_attempts }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6 border-left padding-left-md">
            <table class="table clean">
              <tbody>
              <tr>
                <td><b>Total Deposit</b></td>
                <td class="text-right">Ksh. {{profile.total_deposits | formatAmount}}</td>
              </tr>
              <tr>
                <td><b>Balance</b></td>
                <td class="text-right">Ksh. {{profile.wallet_balance | formatAmount}}</td>
              </tr>
              <tr>
                <td><b>Last Login</b></td>
                <td class="text-right">{{profile.last_login_date }}</td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <sui-tab :menu="{ attached: false }" v-show="loadRecord">
      <sui-tab-pane title="Mpesa Transaction" :attached="false">
        <loader v-show="loading"/>
        <vuetable ref="vuetable"
                  :api-mode="false"
                  :fields="fields"
                  :per-page="perPage"
                  track-by="trxn_id"
                  :data-manager="dataManager"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
                  @vuetable:loading="onLoading"
                  @vuetable:loaded="onLoaded"
        >
        </vuetable>
        <div style="padding-top:10px">
          <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
        </div>
      </sui-tab-pane>
      <sui-tab-pane title="System Transaction" :attached="false">
        <loader v-show="loading"/>
        <vuetable ref="vuetable_system"
                  :api-mode="false"
                  :fields="fieldsSystem"
                  :per-page="perPage"
                  track-by="transaction_id"
                  :data-manager="dataManagerSystem"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
                  @vuetable:loading="onLoading"
                  @vuetable:loaded="onLoaded"
        >
        </vuetable>
        <div style="padding:10px 0">
          <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePageSystem"></vuetable-pagination>
        </div>
      </sui-tab-pane>
      <sui-tab-pane title="Subscriptions" :attached="false">
        <loader v-show="loading"/>
        <vuetable ref="vuetable_sub"
                  :api-mode="false"
                  :fields="fieldsSub"
                  :per-page="perPage"
                  track-by="subscriptionId"
                  :data-manager="dataManagerSub"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
                  @vuetable:loading="onLoading"
                  @vuetable:loaded="onLoaded"
        >
        </vuetable>
        <div style="padding:10px 0">
          <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePageSub"></vuetable-pagination>
        </div>
      </sui-tab-pane>
    </sui-tab>
  </div>
</template>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import store from "../../store";
import {mapActions} from "vuex";
import axios from "axios";
import _ from "lodash";
import numeral from "numeral";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "approvals",

  data() {
    return {
      loading: true,
      loadRecord:false,
      search_match:'',
      phone:'',
      deposit:40,
      fields: [
        {
          name: "mobile_number",
          title: "Phone",
          sortField: "mobile_number",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "trxn_id",
          title: "Transaction ID",
          sortField: "trxn_id",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "amount",
          title: "Amount",
          sortField: "amount",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "customer_name",
          title: "Customer Name",
          sortField: "customer_name",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "account_reference",
          title: "Type",
          sortField: "account_reference",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "paybill",
          title: "Paybill",
          sortField: "paybill",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "created",
          title: "Date",
          sortField: "created",
          callback: "createdAt"
        },
      ],
      fieldsSystem: [
        {
          name: "mobile_number",
          title: "Phone",
          sortField: "mobile_number",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "transaction_reference_id",
          title: "Reference ID",
          sortField: "transaction_reference_id",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "amount",
          title: "Amount",
          sortField: "amount",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "reference_name",
          title: "Reference Name",
          sortField: "reference_name",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "transaction_type",
          title: "Type",
          sortField: "transaction_type",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "source",
          title: "Source",
          sortField: "source",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "channel_name",
          title: "Channel",
          sortField: "channel_name",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "transaction_date",
          title: "Date",
          sortField: "transaction_date",
          callback: "createdAt"
        },
      ],
      fieldsSub: [
        {
          name: "mobileNumber",
          title: "Phone",
          sortField: "mobileNumber",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "packageName",
          title: "Package Name",
          sortField: "packageName",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "packagePrice",
          title: "Price",
          sortField: "packagePrice",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "packageValidity",
          title: "Validity",
          sortField: "packageValidity",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "createdDate",
          title: "Created Date",
          sortField: "createdDate",
          callback: "createdAt"
        },
        {
          name: "expiryDate",
          title: "Expiry Date",
          sortField: "expiryDate",
          callback: "createdAt"
        },
      ],
      sortOrder: [
        {
          field: "created_at",
          direction: "desc"
        }
      ],

      moreParams: {
        start: "",
        filter: "",
        end: ""
      },
      perPage: 5,
      data: [],
      dataSystem: [],
      dataSub: [],
      profile: {},
      match: true
    };
  },

  mounted() {

    var vm = this;

  },

  watch: {
    data(newVal, oldVal) {
      this.reload()
    },
    dataSystem(newVal, oldVal) {
      this.reloadSystem()
    },
    dataSub(newVal, oldVal) {
      this.reloadSub()
    },
  },

  methods: {
    ...mapActions(["searchProfile"]),
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.refresh();

      console.log("start date filter", start);
      console.log("end date filter ", end);
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },

    reload(){
      this.$refs.vuetable.refresh();
    },
    reloadSystem(){
      this.$refs.vuetable_system.refresh();
    },
    reloadSub(){
      this.$refs.vuetable_sub.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onPaginationDataSystem(paginationData) {
      this.$refs.paginationSystem.setPaginationData(paginationData);
    },
    onPaginationDataSub(paginationData) {
      this.$refs.pagination_sub.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onChangePageSystem(page) {
      this.$refs.vuetable_system.changePage(page);
    },
    onChangePageSub(page) {
      this.$refs.vuetable_sub.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    dataManagerSystem(sortOrder, pagination) {
      if (this.dataSystem.length < 1) return;

      let local = this.dataSystem;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable_system.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    dataManagerSub(sortOrder, pagination) {
      if (this.dataSub.length < 1) return;

      let local = this.dataSub;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable_sub.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
    },

    async searchRecord() {
      const payload = {
        'msisdn': this.search_match
      }
      let response = await this.searchProfile(payload)
      if (response.status === 200){

        this.loadRecord = true;
        this.match = true;
        this.profile = response.message.profile
        this.data = response.message.mpesa
        this.dataSystem = response.message.system
        this.dataSub = response.message.subscription
      }
      else {
        this.match = false
      }
    }
  },
  filters: {
    formatAmount(value){
      return numeral(value).format("0,0.00");
    },
    formatNumber(value){
      return numeral(value).format("0,0");
    }
  }
};
</script>
<style scoped>
td.vuetable-slot {
  overflow: visible !important;
}
.l-container-content{
  padding: 1.5em;
}
</style>

<template>
  <div class="l-container-content bg-white">
    <div class="row bg-white-shadow border-bottom border-top">
      <div class="col-4">
        <h3>System Transactions</h3>
      </div>
      <div class="col-8 text-right">
        <select class="select sm hidden" v-model="days" @change="searchRecords(this.$event.value)">
          <option value="0">Today</option>
          <option value="7"> Last 7 Days</option>
          <option value="30"> Last 30 Days</option>
        </select>
        <div class="buttons small">
          <button class="button" @click="searchRecords(0)" :class="{'selected': days === 0}">Today</button>
          <button class="button" @click="searchRecords(3)" :class="{'selected': days === 3}">3d</button>
          <button class="button" @click="searchRecords(7)" :class="{'selected': days === 7}">7d</button>
          <button class="button" @click="searchRecords(30)" :class="{'selected': days === 30}">30d</button>
        </div>
      </div>
      <div class="col">
        <date-picker placeholder="Filter by date" style="width: 100%;" class="" v-model="time3" range @change="dateFilter"></date-picker>
      </div>
    </div>
    <div class="row">
      <loader v-show="loading"/>
      <vuetable ref="vuetable"
                :api-mode="false"
                :fields="fields"
                :per-page="perPage"
                track-by="profile_id"
                :data-manager="dataManager"
                pagination-path="pagination"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded"
      >
      </vuetable>
      <div style="padding-top:10px">
        <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import store from "../../store";
import {mapActions} from "vuex";
import axios from "axios";
import _ from "lodash";
import DatePicker from 'vue2-datepicker';
import moment from "moment";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    DatePicker,
    loader
  },
  name: "approvals",

  data() {
    return {
      loading: true,
      fields: [
        {
          name: "mobile_number",
          title: "Phone",
          sortField: "mobile_number",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "transaction_reference_id",
          title: "Reference ID",
          sortField: "transaction_reference_id",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "amount",
          title: "Amount",
          sortField: "amount",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "reference_name",
          title: "Reference Name",
          sortField: "reference_name",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "transaction_type",
          title: "Type",
          sortField: "transaction_type",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "source",
          title: "Source",
          sortField: "source",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "channel_name",
          title: "Channel",
          sortField: "channel_name",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "transaction_date",
          title: "Date",
          sortField: "transaction_date",
          callback: "createdAt"
        },
      ],
      sortOrder: [
        {
          field: "created_at",
          direction: "desc"
        }
      ],

      moreParams: {
        start: "",
        end: "",
        limit: 1000
      },
      days:3,
      time3:"",
      perPage: 10,
      data: []
    };
  },

  mounted() {

    var vm = this;

    vm.setTransactions()
  },

  watch: {
    data(newVal, oldVal) {
      this.reload()
    }
  },

  methods: {
    ...mapActions(["getTransactions"]),
    dateFilter(){
      this.moreParams.start = this.format_date(this.time3[0])
      this.moreParams.end = this.format_date(this.time3[1])

      this.setTransactions()
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },
    searchRecords(val){
      this.moreParams.start = moment().subtract(val, "days").format("YYYY-MM-DD")
      this.moreParams.end = moment().format("YYYY-MM-DD")

      this.setTransactions()
    },

    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
    },

    async setTransactions() {
      let response = await this.getTransactions(this.moreParams)
      this.data = response.message.data
    }
  }
};
</script>
<style scoped>
td.vuetable-slot {
  overflow: visible !important;
}
.l-container-content{
  padding: 1.5em;
}
</style>

<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col">
        <div class="l-form row">
          <div class="col" v-show="type === 'FILE UPLOAD'">
            <strong>Instructions: </strong><br>
            The file MUST be a <strong>CSV</strong> and in this specific format with these headers: <br>
            <p style="color: red; font-size: 20px">mobile, full_names, custom1, custom2, custom3</p>
            <a href="/assets/sample.csv" download>Download Sample</a>
          </div>
          <div class="col">
            <sui-form>
              <sui-form-field>
                <label>Select Blast Type</label>
                <sui-dropdown
                    placeholder="Select Blast Type"
                    selection
                    :options="types"
                    v-model="type"
                />
              </sui-form-field>
            </sui-form>
          </div>
          <div class="col">
            <sui-form>
              <sui-form-field v-if="type === 'FILE UPLOAD'">
                <input type="file" id="image" name="image" placeholder="Enter Image" accept="text/csv" required>
              </sui-form-field>
              <sui-form-field v-else>
                <label>Select Users</label>
                <sui-dropdown
                    placeholder="Select Users"
                    selection
                    :options="users"
                    v-model="blast_name"
                />
              </sui-form-field>
              <sui-form-field>
                <label>Select Sender ID</label>
                <sui-dropdown
                    placeholder="Select Sender ID"
                    selection
                    :options="codes"
                    v-model="shortCode"
                />
              </sui-form-field>
            </sui-form>
          </div>
          <div class="col">
            <div class="field">
              <label>Message to send</label>
              <div class="ui top attached segment">
                <div class="ui fluid">
                  <textarea id="text" v-model="text" name="w3review" rows="5" style="width: -webkit-fill-available; border: 0"></textarea>
                </div>
              </div>
              <div class="ui bottom attached secondary segment">
                <div class="ui equal width grid">
                  <div class="column"><strong><span v-text="wordCount"></span></strong> character(s)</div>
                  <div class="right aligned column"><strong><span v-text="pageCount"></span></strong> page(s)</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-6">
                <div class="field">
                  <div class="ui checkbox">
                    <input type="checkbox" v-model="schedule">
                    <label>Schedule Message?</label>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div v-show="schedule" class="field">
                  <label>Set Date/Time</label>
                  <input type="datetime-local" class="" v-model="time">

                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <button v-if="query && type === 'DB'" class="button primary" @click="submit(1)">Query Recipients</button>
            <button v-if="!query && type === 'DB'" class="button primary" @click="submit(0)">Send</button>
            <button v-if="type === 'FILE UPLOAD'" class="button primary" @click="submitFile">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions} from "vuex";
import $ from "jquery";
import {random} from "lodash";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "approvals",

  data() {
    return {
      senderId: 1,
      saveTemplate: false,
      wordCount: 0,
      pageCount: 0,
      schedule: null,
      template: '',
      templateMode: "none",
      templates: [],
      time: '',
      senderIds: [],
      text: '',
      blast_name: null,
      shortCode: 'SOKA',
      type: 'DB',
      queryRecipient: 1,
      query: true,
      uniqueId: random(100000, 999999),
      types: [
        { text: 'FILE UPLOAD', value: 'FILE UPLOAD'},
        { text: 'DB', value: 'DB' }
      ],
      users: [
        { text: 'All Users', value: 'full_db' },
        { text: 'Old DB', value: 'old_db' },
        { text: 'App Download', value: 'downloaded' },
        { text: 'App Not Download', value: 'not-downloaded' },
      ],
      codes: [
        { text: 'SOKA', value: 'SOKA' },
      ]
    };
  },
  watch: {
    text: function (val,oldVal){
      if(val !== oldVal) {
        var count = val.length;
        // Display it as output
        this.wordCount = count;

        var page = count/160;
        this.pageCount = Math.ceil(page);
      }
    },
  },

  methods: {
    ...mapActions(["sendSms", "sendSmsFile"]),
    async submit(query) {
      let app = this
      let isScheduled = '0'
      let res = 'Yes, send!'
      let res1 = 'Doing this sends this sms!'
      if (this.schedule){
        isScheduled = '1'
        if (!this.time){

          app.$swal.fire('Missing!', "Fill all the required fields", 'error')
          return;
        }
      }

      if (!this.shortCode || !this.text || !this.blast_name){

        app.$swal.fire('Missing!', "Fill all the required fields", 'error')
        return;
      }

      if (query === 1){
        this.queryRecipient = 1
        res = 'Yes, Query!'
        res1 = 'Doing this queries the DB!'
      }
      else {
        this.queryRecipient = null
      }
      const payload = {
        shortCode: this.shortCode,
        message: this.text,
        queryRecipient: this.queryRecipient,
        uniqueId: this.uniqueId,
        isScheduled: isScheduled,
        time: this.time,
        blast_name: this.blast_name,
      };

      /*
      if( document.getElementById("image").files.length === 0 ){
        app.$swal.fire('Missing!', "File is required", 'error')
        return;
      }
      */

      app.$swal.fire({
        title: 'Are you sure?',
        text: res1,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: res,
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.sendSms(payload)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.$swal.fire('Success!', result.value.message, 'success')
              if (!app.query){
                app.resetForm()
              }
              app.query = false
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },
    async submitFile() {
      let app = this
      let isScheduled = '0'
      let res = 'Yes, send!'
      let res1 = 'Doing this sends this sms!'
      if (this.schedule){
        isScheduled = '1'
        if (!this.time){

          app.$swal.fire('Missing!', "Fill all the required fields", 'error')
          return;
        }
      }

      if (!this.shortCode || !this.text){

        app.$swal.fire('Missing!', "Fill all the required fields", 'error')
        return;
      }

      const payload = new FormData();
      payload.append('shortCode', this.shortCode)
      payload.append('message', this.text)
      payload.append('uniqueId', this.uniqueId)
      payload.append('isScheduled', isScheduled)
      payload.append('time', this.time)
      payload.append('image', $('#image')[0].files[0])

      if( document.getElementById("image").files.length === 0 ){
        app.$swal.fire('Missing!', "File is required", 'error')
        return;
      }

      app.$swal.fire({
        title: 'Are you sure?',
        text: res1,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: res,
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.sendSmsFile(payload)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.$swal.fire('Success!', result.value.message, 'success')
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },
    resetForm(){
      this.text = ''
      this.time = ''
    }
  }
};
</script>
<style scoped>
td.vuetable-slot {
  overflow: visible !important;
}
.l-container-content{
  padding: 1.5em;
}
</style>

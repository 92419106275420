<template>
  <div>
    <div class="l-container-content bg-white">
      <div class="row">
        <div class="col-6">
          <h3>Custom Prediction</h3>
        </div>
        <div class="col-6 text-right">
          <router-link :to="{name: 'predictionTag'}" class="button primary">Add Tag</router-link>
        </div>
      </div>
      <div class="row">
        <loader v-show="loading"/>
        <vuetable ref="vuetable"
                  :api-mode="false"
                  :fields="fields"
                  :per-page="perPage"
                  track-by="event_id"
                  :data-manager="dataManager"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
                  @vuetable:loading="onLoading"
                  @vuetable:loaded="onLoaded"
        >
          <template slot="actions" slot-scope="props">
            <button class="small ui button blue" @click="toggle(props.rowData.event_id)">View Tag</button>
          </template>
          <template slot="name" slot-scope="props">
            <strong>{{ props.rowData.home_team }}</strong> vs <strong>{{ props.rowData.away_team }}</strong>
          </template>
        </vuetable>
        <div style="padding-top:10px">
          <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
        </div>
      </div>
    </div>

    <!--  Modal | Edit Jackpot-->
    <sui-modal v-model="open">
      <sui-modal-header>Prediction Tag</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <sui-form>
            <sui-form-field>
              <label>Sponsor Link</label>
              <input placeholder="Sponsor Link" type="text" v-model="tag.sponsor_link" disabled/>
            </sui-form-field>
            <sui-form-field>
              <label>Sponsor Image Link</label>
              <input placeholder="Sponsor Image Link" type="text" v-model="tag.sponsor_image_link" disabled/>
            </sui-form-field>
            <sui-form-fields fields="two">
              <sui-form-field>
                <label>UTM Source</label>
                <input placeholder="UTM Source" type="text" v-model="tag.utm_source" disabled/>
              </sui-form-field>
              <sui-form-field>
                <label>UTM Campaign</label>
                <input placeholder="UTM Campaign" type="text" v-model="tag.utm_campaign" disabled/>
              </sui-form-field>
            </sui-form-fields>
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click.native="resetForm"> Close </sui-button>
<!--        <sui-button color="blue" @click.native=""> Update </sui-button>-->
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import store from "../../store";
import {mapActions} from "vuex";
import axios from "axios";
import _ from "lodash";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "approvals",

  data() {
    return {
      open: false,
      loading: true,
      fields: [
        {
          name: "competition_name",
          title: "Competition Name",
          sortField: "competition_name",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "name",
          title: "Tams",
          sortField: "name",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "prediction",
          title: "Prediction",
          sortField: "prediction",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
        {
          name: "game_time",
          title: "Game Time",
          sortField: "game_time",
          callback: "createdAt"
        },
        {
          name: "actions",
          title: "Action",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
      ],
      sortOrder: [
        {
          field: "created_at",
          direction: "desc"
        }
      ],
      moreParams: {
        start: "",
        filter: "",
        end: ""
      },
      perPage: 10,
      data: [],
      checkedIds: [],
      tag: {},
    };
  },

  mounted() {

    var vm = this;

    vm.setPredictions()
  },

  watch: {
    data(newVal, oldVal) {
      this.reload()
    }
  },

  methods: {
    ...mapActions(["getPredictions", "editLink", "getLink", "fillFixtureId"]),
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.refresh();

      console.log("start date filter", start);
      console.log("end date filter ", end);
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },
    async toggle(id) {
      let app = this;
      let payload = { fixture_id: id }
      let response = await this.getLink(payload)
      console.log("tag: " + JSON.stringify(response))
      if (response.status === 200) {
        this.tag = response.message[0]
        this.open = !this.open;
      }
      else
        //this.$swal.fire("No Content!", "No prediction tag for this fixture", "info");
        this.$swal.fire({
          title: 'No Content!',
          text: "No prediction tag for this fixture",
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: 'gray',
          cancelButtonText: 'Close',
          confirmButtonText: 'Add!'
        }).then((result) => {
          if (result.isConfirmed) {
            // got to add tag
            this.fillFixtureId(id);
            this.$router.push({name: 'predictionTag'})
          }
        })
    },
    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
    },

    async setPredictions() {
      let response = await this.getPredictions(3)
      this.data = response.message
    },

    async updateLink() {
      let app = this

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this updates the link!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.editLink(app.tag)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Updated!', result.value.message, 'success')
              app.reload()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    resetForm() {
      this.tag = {}
      this.open = false
    }
  }
};
</script>
<style scoped>
td.vuetable-slot {
  overflow: visible !important;
}
.l-container-content{
  padding: 1.5em;
}
</style>

<template>
  <div class="l-main">
    <div class="l-main-content" style="padding: 15px" v-if="status === 200">
      <div class="row bg-white-shadow border-bottom border-top">
        <div class="col-4">
          <h3>Summary</h3>
        </div>
        <div class="col-8 text-right">
          <select class="select sm hidden" v-model="days" @change="searchRecords(this.$event.value)">
            <option value="0">Today</option>
            <option value="7"> Last 7 Days</option>
            <option value="30"> Last 30 Days</option>
          </select>
          <div class="buttons small">
            <button class="button" @click="searchRecords(0)" :class="{'selected': days === 0}">Today</button>
            <button class="button" @click="searchRecords(3)" :class="{'selected': days === 3}">3d</button>
            <button class="button" @click="searchRecords(7)" :class="{'selected': days === 7}">7d</button>
            <button class="button" @click="searchRecords(30)" :class="{'selected': days === 30}">30d</button>
          </div>
        </div>
        <div class="col">
          <date-picker placeholder="Filter by date" style="width: 100%;" class="" v-model="time3" range
                       @change="dateFilter"></date-picker>
        </div>
      </div>

      <div class="row bg-white-shadow border-bottom border-top">
        <div class="rsum">
          <div class="sum blue">
            <div class="h">Profiles</div>
            <div class="t">{{ numbers.profiles }}</div>
            <div class="s hidden"><i class="fa fa-caret-up"></i> {{ deposit / (lastdeposit) * 100 | formatPercent }}%
            </div>
          </div>
          <div class="sum green" v-if="this.$store.state.auth.acl_id !== 6">
            <div class="h">Sum Deposit</div>
            <div class="t">{{ numbers.mpesa.sum | formatAmount }}</div>
            <div class="s hidden"><i class="fa fa-caret-up"></i> {{ deposit / (lastdeposit) * 100 | formatPercent }}%
            </div>
          </div>
          <div class="sum purple" v-if="this.$store.state.auth.acl_id !== 6">
            <div class="h">Count Deposit</div>
            <div class="t">{{ numbers.mpesa.count }}</div>
            <div class="s hidden"><i class="fa fa-caret-up"></i> {{ deposit / (lastdeposit) * 100 | formatPercent }}%
            </div>
          </div>
        </div>
        <div class="rsum" v-if="this.$store.state.auth.acl_id !== 6">
          <div class="sum yellow">
            <div class="h">Sum Credit</div>
            <div class="t">{{ numbers.transaction_credit.sum | formatAmount }}</div>
            <div class="s hidden"><i class="fa fa-caret-down"></i> {{ profit / (lastprofit) * 100 | formatPercent }}%
            </div>
          </div>
          <div class="sum blue">
            <div class="h">Count Credit</div>
            <div class="t">{{ numbers.transaction_credit.count }}</div>
            <div class="s hidden"><i class="fa fa-caret-down"></i> {{ profit / (lastprofit) * 100 | formatPercent }}%
            </div>
          </div>
          <div class="sum green">
            <div class="h">Sum Debit</div>
            <div class="t">{{ numbers.transaction_debit.sum | formatAmount }}</div>
            <div class="s hidden"><i class="fa fa-caret-down"></i> {{ profit / (lastprofit) * 100 | formatPercent }}%
            </div>
          </div>
          <div class="sum purple">
            <div class="h">Count Debit</div>
            <div class="t">{{ numbers.transaction_debit.count }}</div>
            <div class="s hidden"><i class="fa fa-caret-down"></i> {{ profit / (lastprofit) * 100 | formatPercent }}%
            </div>
          </div>
        </div>
        <div class="rsum">
          <div class="sum purple">
            <div class="h">Total Website Visitors</div>
            <div class="t">{{ numbers.total_hits }}</div>
            <div class="s hidden"><i class="fa fa-caret-up"></i> {{ deposit / (lastdeposit) * 100 | formatPercent }}%
            </div>
          </div>
          <div class="sum yellow">
            <div class="h">New Website Visitors</div>
            <div class="t">{{ numbers.new_hits }}</div>
            <div class="s hidden"><i class="fa fa-caret-up"></i> {{ deposit / (lastdeposit) * 100 | formatPercent }}%
            </div>
          </div>
          <div class="sum teal">
            <div class="h">Returning Website Visitors</div>
            <div class="t">{{ numbers.total_hits - numbers.new_hits }}</div>
            <div class="s hidden"><i class="fa fa-caret-up"></i> {{ deposit / (lastdeposit) * 100 | formatPercent }}%
            </div>
          </div>
        </div>
      </div>
      <!--
                  <div class="row bg-white-shadow border-bottom border-top">
                      <div class="col">
                          <h3>Total purchases</h3>
                          <small class="text-grey-light">Products bought</small>
                      </div>
                      <div class="col">
                          <div class="overflow">
                              <table class="table simple" id="purchaseTable">
                                  <thead class="med">
                                      <tr>
                                          <th>Product</th>
                                          <th class="text-center">%</th>
                                          <th class="long text-center">Count</th>
                                          <th class="long text-center">Amount</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="(entry, index) in inventory_stats" :key="index+1">
                                          <td class="text-grey text-left">{{entry.name}}</td>
                                          <td class="text-grey text-center">{{((entry.amount/totalAmount)*100) | formatPercent }}%</td>
                                        <td class="text-grey text-center">{{entry.count}}</td>
                                          <td class="long text-center">{{entry.amount | formatAmount}}</td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
                -->
      <div class="row bg-white-shadow border-bottom border-top">
        <div class="row">
          <div class="col-6">
            <h3 class="text-center">Visitors grouped by Browser</h3>
            <apexchart :key="key" type="pie" height="350" :options="chartOptions" :series="series"></apexchart>
          </div>
          <div class="col-6">
            <h3 class="text-center">Visitors grouped by Source</h3>
            <apexchart :key="key" type="pie" height="350" :options="chartOptions_src" :series="series_src"></apexchart>
          </div>
        </div>
      </div>
      <div class="row bg-white-shadow border-bottom border-top">
        <div class="row">
          <div class="col-6">
            <h3 class="text-center">Visitors grouped by OS</h3>
            <apexchart :key="key" type="pie" height="350" :options="chartOptions_os" :series="series_os"></apexchart>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import store from "../store";
import $ from 'jquery';
import numeral from "numeral";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {mapActions} from "vuex";
import {random} from "lodash";

export default {
  components: {DatePicker},
  data() {
    return {
      selectedTab: 'summary',
      net: 0,
      profit: 90,
      lastdeposit: 0,
      lastnet: 0,
      lastprofit: 0,
      customers: 5,
      totalProducts: [
        {
          name: 'product1',
          totalAmount: 120
        },
        {
          name: 'product2',
          totalAmount: 80
        }
      ],
      channels: [],
      channelAttribute: [
        {
          utm_campaign: 'Safaricom',
          total: 2,
          amount: 80
        },
        {
          utm_campaign: 'Google',
          total: 3,
          amount: 120
        },
      ],
      days: 3,
      SMS: 0,
      percentSMS: 0,
      TELEGRAM: 0,
      percentTELEGRAM: 0,
      USSD: 0,
      percentUSSD: 0,
      WEB: 0,
      percentWEB: 0,
      WHATSAPP: 0,
      percentWHATSAPP: 0,
      newCustomer: 2,
      percentNewCustomer: 40,
      returnCustomer: 3,
      percentReturnCustomer: 60,
      totalAmountDeposit: 200,
      time3: "",
      startDate: "",
      endDate: "",
      profile: 0,
      deposit: 0,
      purchases: 0,
      inventory_stats: [],
      utm_stats: [],
      totalAmount: 0,
      return_customer: 0,
      new_customer: 0,
      customerComparison: [],
      daily_deposit: [],
      numbers: null,

      series: [],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 400
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },

      series_src: [],
      chartOptions_src: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 400
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },

      series_os: [],
      chartOptions_os: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 400
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      key: '2',
      status: 404
    }
  },
  mounted() {

    let vm = this;
    //vm.loadChart()
    //vm.loadCustomerChart()
    vm.stats()

  },
  methods: {
    ...mapActions(["Stats"]),
    searchRecords(val) {
      this.startDate = moment().subtract(val, "days").format("YYYY-MM-DD")
      this.endDate = moment().format("YYYY-MM-DD")

      this.stats()
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
    },
    dateFilter() {
      this.startDate = this.format_date(this.time3[0])
      this.endDate = this.format_date(this.time3[1])

      this.stats()
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async stats() {
      let app = this
      const payload = {
        'start': this.startDate,
        'end': this.endDate
      }
      let response = await this.Stats(payload);
      console.log('response: ' + JSON.stringify(response))
      if (response.status === 200) {
        this.numbers = response.message
        this.series = this.numbers.grp_browser.values
        this.chartOptions.labels = this.numbers.grp_browser.labels
        this.series_src = this.numbers.grp_src.values
        this.chartOptions_src.labels = this.numbers.grp_src.labels
        this.series_os = this.numbers.grp_os.values
        this.chartOptions_os.labels = this.numbers.grp_os.labels
        this.key = random(1, 999)
      }
      app.status = response.status
    },

    initCharts() {

    }
  },
  filters: {
    formatAmount(value) {
      return numeral(value).format("0,0.00");
    },
    formatPercent(value) {
      return numeral(value).format("0,0.00");
    },
    formatNumber(value) {
      return numeral(value).format("0,0");
    }
  }
}
</script>

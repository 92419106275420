<template>
    <div class="l-navigation">
        <div class="l-navigation-bg"></div>
        <div class="l-navigation-container">
            <ul>
                <li :class="{'active': this.$route.name === 'dashboard'}">
                    <router-link :to="{name: 'dashboard'}"><i class="fa fa-chart-pie fa-fw"></i><span>Dashboard</span></router-link>
                </li>
<!--
                <li :class="{'active': this.$route.name == 'purchases'}">
                    <router-link :to="{name: 'purchases'}"><i class="fa fa-shopping-bag fa-fw"></i><span>Purchases</span></router-link>
                </li>
                <li :class="{'active': this.$route.name == 'customers'}">
                    <router-link :to="{name: 'customers'}"><i class="fa fa-users fa-fw"></i><span>Customers</span></router-link>
                </li>
              -->
              <li class="" :class="{'active': this.$route.name === 'transaction'}" v-if="this.$store.state.auth.acl_id !== 6">
                <router-link :to="{name: 'transaction'}"><i class="fa fa-receipt fa-fw"></i><span>Transaction</span></router-link>
              </li>
              <li class="" :class="{'active': this.$route.name === 'deposit'}" v-if="this.$store.state.auth.acl_id !== 6">
                <router-link :to="{name: 'deposit'}"><i class="fa fa-dollar-sign fa-fw"></i><span>Deposit</span></router-link>
              </li>
              <li class="" :class="{'active': this.$route.name === 'customers' || this.$route.name === 'customersAll' || this.$route.name === 'CustomerSearch' || this.$route.name === 'SMS'}" v-if="this.$store.state.auth.acl_id !== 6">
                <router-link :to="{name: 'CustomerSearch'}"><i class="fa fa-users fa-fw"></i><span>Customers</span></router-link>
              </li>
              <li class="" :class="{'active': this.$route.name === 'prediction' || this.$route.name === 'AddPrediction' || this.$route.name === 'jackpot' || this.$route.name === 'AddJackpot' || this.$route.name === 'predictionTag'}" v-if="this.$store.state.auth.acl_id !== 6">
                <router-link :to="{name: 'prediction'}"><i class="fa fa-forward fa-fw"></i><span>Predictions</span></router-link>
              </li>
              <li class="" :class="{'active': this.$route.name === 'category' || this.$route.name === 'news' || this.$route.name === 'addNews' || this.$route.name === 'editNews' || this.$route.name === 'tag' || this.$route.name === 'ads'}">
                <router-link :to="{name: 'news'}"><i class="fa fa-newspaper fa-fw"></i><span>Articles</span></router-link>
              </li>
                <li class="p-top p-bottom"></li>
                <li class="" :class="{'active': this.$route.name === 'admin' || this.$route.name === 'tag.vue'}" v-if="this.$store.state.auth.acl_id !== 6">
                    <router-link :to="{name: 'admin'}"><i class="fa fa-users-cog fa-fw"></i><span>Users</span></router-link>
                </li>
            </ul>
            <div class="l-navigation-options">
            <button v-on:click="logout()" class="button white">Logout</button>
            </div>
        </div>
    </div>
</template>
<script>
import store from "../store";
import $ from 'jquery'
import {mapActions} from "vuex";
export default {
     data:function(){
        return{
            stateAdmin:true,
            stateAuditor:false,
            stateCS:false,
          permission: store.state.auth.permission
        }
    },
    mounted() {
        var vm = this;
        $(".l-navigation .l-navigation-container ul li").each(function(){
            $(this).click(function(){
                vm.$parent.showNav = false;
            });
        });
        $(".l-navigation-bg").click(function(){
            vm.$parent.showNav = false;
        });
        // if(vm.$cookies.get("role_id") == 1){
        //     vm.stateAdmin = true;
        // }
        // if(vm.$cookies.get("role_id") == 2){
        //     vm.stateAuditor = true;
        // }
        // if(vm.$cookies.get("role_id") == 3){
        //     vm.stateCS = true;
        // }
    },
    methods: {
      ...mapActions(["LogOut"]),
       logout() {
            this.LogOut()
        },

    }
}
</script>

<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col">
        <div class="text-title">
          <h3>Add News Article</h3>
        </div>
        <sui-form>
          <sui-form-field>
            <label>Title</label>
            <input placeholder="Title" v-model="form.title"/>
          </sui-form-field>
          <sui-form-fields fields="two">
            <sui-form-field>
              <label>Select Category</label>
              <sui-dropdown
                  placeholder="Category"
                  selection
                  :options="categories"
                  v-model="form.category_id"
              />
            </sui-form-field>
            <sui-form-field>
              <label>Priority</label>
              <input placeholder="priority" v-model="form.priority"/>
            </sui-form-field>
          </sui-form-fields>
          <sui-form-field>
            <label>Summary</label>
            <input placeholder="Summary" v-model="form.summary"/>
          </sui-form-field>
          <sui-form-field>
            <label>Page Description</label>
            <input placeholder="Page Description" v-model="form.page_desc"/>
          </sui-form-field>
          <sui-form-field>
            <label>Page Keywords</label>
            <textarea placeholder="Page Keywords" v-model="form.page_keywords" cols="3"></textarea>
          </sui-form-field>
          <sui-form-field>
            <label>Content</label>
            <ckeditor :editor="editor" v-model="form.description" @ready="setForm" :config="editorConfig"></ckeditor>
          </sui-form-field>
          <sui-form-field>
            <label>Sponsor Link</label>
            <input placeholder="Sponsor Link" v-model="form.sponsor_link"/>
          </sui-form-field>
          <sui-form-field>
            <label>Sponsor Image URL</label>
            <input type="file" id="image" name="image" placeholder="Enter Image" accept="image/*" required>
          </sui-form-field>
          <sui-form-fields fields="two">
            <sui-form-field>
              <label>UTM Source</label>
              <input placeholder="UTM Source" v-model="form.utm_source"/>
            </sui-form-field>
            <sui-form-field>
              <label>UTM Campaign</label>
              <input placeholder="UTM Campaign" v-model="form.utm_campaign"/>
            </sui-form-field>
          </sui-form-fields>
          <sui-form-field>
            <label>Select Tags</label>
            <sui-dropdown
                placeholder="Tags"
                selection
                search
                multiple
                :options="tags"
                v-model="form.tags_id"
            />
          </sui-form-field>
          <sui-button color="blue" fluid size="large" @click.prevent="updateArticle"> Update Article </sui-button>
        </sui-form>
      </div>
    </div>
  </div>
</template>
<script>
import loader from "@/components/loader";
import {mapActions} from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  data: function() {
    return {
      form: {
        id: null,
        title: null,
        summary: null,
        description: this.$store.state.auth.article.description,
        category_id: null,
        utm_source: null,
        utm_campaign: null,
        sponsor_link: null,
        sponsor_image_url: null,
        priority: null,
        page_desc: '',
        page_keywords: '',
        tags_id: null,
        status: null,
      },
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
      categories: [],
      tags: []
    };
  },
  components: {
    loader
  },
  methods: {
    ...mapActions(["editNewsArticle", "getNewsCategory", "getNewsTag"]),
    async setCategories() {
      let app = this
      let response = await this.getNewsCategory()
      if (response.status === 200){
        //console.log("categories: " + JSON.stringify(response.message))
        response.message.forEach(function (item) {
          let name = item.name
          let data = { text: name, value: item.id }
          app.categories.push(data)
        })
      }
    },
    async setTags() {
      let app = this
      let response = await this.getNewsTag()
      if (response.status === 200){
        //console.log("categories: " + JSON.stringify(response.message))
        response.message.forEach(function (item) {
          let name = item.name
          let data = { text: name, value: item.id }
          app.tags.push(data)
        })
      }
    },

    async updateArticle() {
      let app = this

      const payload = new FormData();
      payload.append('id', this.form.id)
      payload.append('title', this.form.title)
      payload.append('summary', this.form.summary)
      payload.append('description', this.form.description)
      payload.append('category_id', this.form.category_id)
      payload.append('tags_id', this.form.tags_id)
      payload.append('utm_source', this.form.utm_source)
      payload.append('utm_campaign', this.form.utm_campaign)
      payload.append('sponsor_link', this.form.sponsor_link)
      payload.append('priority', this.form.priority)
      payload.append('page_desc', this.form.page_desc)
      payload.append('page_keywords', this.form.page_keywords)
      payload.append('status', this.form.status)

      if( document.getElementById("image").files.length !== 0 ){
        payload.append('image', $('#image')[0].files[0])
      }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this updates this article!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.editNewsArticle(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire({
                title: 'Updated!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                await this.$router.push({name: 'news'})
              })
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    setForm: function () {
      let data = this.$store.state.auth.article
      this.form.id = data.id
      this.form.title = data.title
      this.form.summary = data.summary
      this.form.description = data.description
      this.form.category_id = data.category_id
      this.form.utm_source = data.utm_source
      this.form.utm_campaign = data.utm_campaign
      this.form.sponsor_link = data.sponsor_link
      this.form.sponsor_image_url = data.sponsor_image_url
      this.form.priority = data.priority
      this.form.page_desc = data.page_desc
      this.form.page_keywords = data.page_keywords
      this.form.status = data.status
      this.form.tags_id = JSON.parse(data.tags_id)
    }
  },
  mounted() {
    this.setCategories()
    this.setForm()
    this.setTags()
  },
};
</script>
<style scoped>
.l-container-content{
  padding: 1.5em;
}
</style>

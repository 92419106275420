<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col">
        <div class="text-title">
          <h3>Add Jackpot</h3>
        </div>
        <sui-form>
          <sui-form-field>
            <label>Select Name</label>
            <sui-dropdown
                placeholder="Jackpot"
                selection
                :options="jackpots"
                v-model="match.reference_id"
            />
          </sui-form-field>
        </sui-form>
        <br />
        <sui-form v-show="match.reference_id === '-1'">
          <sui-form-fields fields="two">
            <sui-form-field >
              <label>Jackpot Name</label>
              <input placeholder="Jackpot Name" v-model="form.name"/>
            </sui-form-field>
            <sui-form-field>
              <label>Category</label>
              <sui-dropdown
                  placeholder="Category"
                  selection
                  :options="categories"
                  v-model="form.category_id"
              />
            </sui-form-field>
          </sui-form-fields>
          <sui-form-field>
            <label>Description</label>
            <input placeholder="Description" v-model="form.description"/>
          </sui-form-field>
          <sui-form-fields fields="three">
            <sui-form-field>
              <label>No of Games</label>
              <input placeholder="No of Games" type="number" v-model="form.number_of_games"/>
            </sui-form-field>
            <sui-form-field>
              <label>Start Time</label>
              <input placeholder="Start Time" type="datetime-local" v-model="form.starting_date"/>
            </sui-form-field>
            <sui-form-field>
              <label>End Time</label>
              <input placeholder="End Time" type="datetime-local" v-model="form.ending_date"/>
            </sui-form-field>
          </sui-form-fields>
          <sui-button color="blue" fluid size="large" @click.prevent="createJackpot"> Add Jackpot </sui-button>
        </sui-form>
        <div v-show="match.reference_id && match.reference_id !== '-1'">
          <sui-form>
            <sui-form-fields fields="two">
              <sui-form-field>
                <label>League Name</label>
                <input placeholder="League Name" type="text" v-model="match.league_name"/>
              </sui-form-field>
              <sui-form-field>
                <label>Venue</label>
                <input placeholder="Venue" type="text" v-model="match.venue"/>
              </sui-form-field>
            </sui-form-fields>
            <sui-form-fields fields="two">
              <sui-form-field>
                <label>Home Team</label>
                <input placeholder="Home Team" type="text" v-model="match.home_team"/>
              </sui-form-field>
              <sui-form-field>
                <label>Away Team</label>
                <input placeholder="Away Team" type="text" v-model="match.away_team"/>
              </sui-form-field>
            </sui-form-fields>
            <sui-form-fields fields="three">
              <sui-form-field>
                <label>Odds Home</label>
                <input placeholder="Odds Home" type="number" v-model="match.odds_home"/>
              </sui-form-field>
              <sui-form-field>
                <label>Odds Draw</label>
                <input placeholder="Odds Draw" type="number" v-model="match.odds_draw"/>
              </sui-form-field>
              <sui-form-field>
                <label>Odds Away</label>
                <input placeholder="Odds Away" type="number" v-model="match.odds_away"/>
              </sui-form-field>
            </sui-form-fields>
            <sui-form-fields fields="three">
              <sui-form-field>
                <label>Prediction 1</label>
                <input placeholder="Prediction 1" type="text" v-model="match.prediction1"/>
              </sui-form-field>
              <sui-form-field>
                <label>Prediction 2</label>
                <input placeholder="Prediction 2" type="text" v-model="match.prediction2"/>
              </sui-form-field>
              <sui-form-field>
                <label>Game Time</label>
                <input placeholder="Game Time" type="datetime-local" v-model="match.match_time"/>
              </sui-form-field>
            </sui-form-fields>
            <sui-button color="blue" fluid size="large" @click.prevent="addForm"> Add Prediction </sui-button>
          </sui-form>
          <sui-divider section class="py-2"/>
          <sui-table striped celled v-if="entries.length > 0">
            <sui-table-header>
              <sui-table-row>
                <sui-table-header-cell>League Name</sui-table-header-cell>
                <sui-table-header-cell>Venue</sui-table-header-cell>
                <sui-table-header-cell>Odds</sui-table-header-cell>
                <sui-table-header-cell>Home Team</sui-table-header-cell>
                <sui-table-header-cell>Away Team</sui-table-header-cell>
                <sui-table-header-cell>Prediction 1</sui-table-header-cell>
                <sui-table-header-cell>Prediction 2</sui-table-header-cell>
                <sui-table-header-cell>Match Time</sui-table-header-cell>
                <sui-table-header-cell>Action</sui-table-header-cell>
              </sui-table-row>
            </sui-table-header>
            <sui-table-body>
              <sui-table-row v-for="(entry, index) in entries" :key="index">
                <sui-table-cell>{{ entry.league_name }}</sui-table-cell>
                <sui-table-cell>{{ entry.venue }}</sui-table-cell>
                <sui-table-cell>1({{ entry.odds_home }}) X({{ entry.odds_draw	 }}) 2({{ entry.odds_away }})</sui-table-cell>
                <sui-table-cell>{{ entry.home_team }}</sui-table-cell>
                <sui-table-cell>{{ entry.away_team }}</sui-table-cell>
                <sui-table-cell>{{ entry.prediction1 }}</sui-table-cell>
                <sui-table-cell>{{ entry.prediction2 }}</sui-table-cell>
                <sui-table-cell>{{ entry.match_time }}</sui-table-cell>
                <sui-table-cell><sui-button size="mini" color="red" content="X" @click.prevent="removeEntry(index)"/></sui-table-cell>
              </sui-table-row>
            </sui-table-body>
          </sui-table>
          <div class="pb-5" v-if="entries.length > 0" >
            <sui-divider section class="py-2"/>

            <sui-button color="blue" class="pull-right" @click.prevent="createJackpotMatches">Submit</sui-button>
            <sui-button class="pull-right" @click.prevent="removeEntry('all')">Cancel</sui-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loader from "@/components/loader";
import {mapActions} from "vuex";

export default {
  data: function() {
    return {
      form: {
        name: null,
        description: null,
        reference_id: null,
        starting_date: null,
        category_id: null,
        ending_date: null,
        number_of_games: null,
      },
      match: {
        reference_id: null,
        home_team: null,
        away_team: null,
        prediction1: null,
        prediction2: null,
        match_time: null,
        league_name: null,
        venue: null,
        odds_home: null,
        odds_draw: null,
        odds_away: null,
      },
      categories: [],
      jackpots: [
        { text: 'New Jackpot', value: '-1' }
      ],
      entries: []
    };
  },
  components: {
    loader
  },
  methods: {
    ...mapActions(["addJackpot", "getJackpots", "getCategories"]),
    async createJackpot() {
      let app = this

      const payload = app.form;

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this adds a new jackpot!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.addJackpot(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              await app.setJackpots();
              app.match.reference_id = result.value.id
              app.$swal.fire('Added!', result.value.message, 'success')
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async createJackpotMatches() {
      let app = this;

      app.$swal
          .fire({
            title: "Are you sure?",
            text: "Doing this adds the matches!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, add!",
            closeOnConfirm: false,
            showLoaderOnConfirm: true,
            preConfirm: async (res) => {

              let response
              let vm = this
              for (const entry of app.entries) {
                response = await vm.addJackpot(entry)
              }
              return response
            },
          })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result));
            if (result.value.status === 200) {
              app.$swal.fire({
                title: "Added!",
                text: result.value.message,
                icon: "success"
              }) .then(async (result) => {
                this.$router.push({ name: "jackpot" })
              })
            } else {
              app.$swal.fire("Error!", result.value.message, "error");
            }
          });
    },

    async setCategories() {
      let app = this
      let payload = {}
      let response = await this.getCategories(payload)
      if (response.status === 200){
        //console.log("categories: " + JSON.stringify(response.message))
        response.message.forEach(function (item) {
          let name = item.alias_name
          let data = { text: name, value: item.id }
          app.categories.push(data)
        })
      }
    },

    async setJackpots() {
      let app = this
      let response = await this.getJackpots()
      if (response.status === 200){
        console.log("leagues: " + JSON.stringify(response.message))
        response.message.forEach(function (item) {
          let data = { text: item.name, value: item.id }
          app.jackpots.push(data)
        })
      }
    },

    notifyVue(message, type) {
      this.$notify({
        timeout: 2500,
        message: message,
        horizontalAlign: "center",
        verticalAlign: "top",
        type: type,
      });
    },

    addForm() {
      if ( !this.match.reference_id || !this.match.home_team || !this.match.away_team || !this.match.prediction1 || !this.match.prediction2 || !this.match.match_time || !this.match.league_name || !this.match.venue || !this.match.odds_home || !this.match.odds_draw || !this.match.odds_away)
        return this.notifyVue("Check all the required fields", "danger")

      let entry = {
        reference_id: this.match.reference_id,
        home_team: this.match.home_team,
        away_team: this.match.away_team,
        prediction1: this.match.prediction1,
        prediction2: this.match.prediction2,
        match_time: this.match.match_time,
        league_name: this.match.league_name,
        venue: this.match.venue,
        odds_home: this.match.odds_home,
        odds_draw: this.match.odds_draw,
        odds_away: this.match.odds_away,
      };
      this.entries.push(entry)
      this.resetMatch()
      return this.notifyVue("Entry added", "success")
    },

    removeEntry(index) {
      if (index === "all"){
        this.entries = []
      }
      else
        this.entries.splice(index, 1)
    },

    resetForm: function () {
      this.form.name = null
      this.form.email_address = null
      this.form.description = null
      this.form.starting_date = null
      this.form.category_id = null
      this.form.ending_date = null
    },

    resetMatch: function () {
      this.match.home_team = null
      this.match.away_team = null
      this.match.prediction1 = null
      this.match.prediction2 = null
      this.match.match_time = null
      this.match.league_name = null
      this.match.venue = null
      this.match.odds_home = null
      this.match.odds_away = null
      this.match.odds_draw = null
    }
  },
  mounted() {
    this.setJackpots()
    this.setCategories()
  }
};
</script>
<style scoped>
.l-container-content{
  padding: 1.5em;
}
</style>

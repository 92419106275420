<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col">
        <div class="text-title">
          <h3>Update Prediction</h3>
        </div>
        <sui-form>
          <sui-form-fields fields="four">
            <sui-form-field>
              <label>No of Games</label>
              <input placeholder="No of Games" type="number" v-model="form.number_of_games"/>
            </sui-form-field>
            <sui-form-field>
              <label>Total Odds</label>
              <input placeholder="Total Odds" type="number" v-model="form.total_odds"/>
            </sui-form-field>
            <sui-form-field>
              <label>Start Time</label>
              <input placeholder="Start Time" type="datetime-local" v-model="form.starting_date"/>
            </sui-form-field>
            <sui-form-field>
              <label>End Time</label>
              <input placeholder="End Time" type="datetime-local" v-model="form.ending_date"/>
            </sui-form-field>
          </sui-form-fields>
          <sui-form-field>
            <label>Prediction</label>
            <textarea v-model="form.prediction"></textarea>
          </sui-form-field>
          <sui-button color="blue" fluid size="large" @click.prevent="updatePrediction"> Update Prediction </sui-button>
        </sui-form>
      </div>
    </div>
  </div>
</template>
<script>
import loader from "@/components/loader";
import {mapActions} from "vuex";
export default {
  data: function() {
    return {
      form: {
        id: null,
        name: null,
        number_of_games: null,
        prediction: null,
        starting_date: null,
        ending_date: null,
        total_odds: null
      },
    };
  },
  components: {
    loader
  },
  methods: {
    ...mapActions(["editCustomPrediction", "getLeagues", "getCategories"]),
    async updatePrediction() {
      let app = this

      let payload = this.form

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this edits this prediction!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.editCustomPrediction(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire({
                title: 'Updated!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                await this.$router.push({name: 'prediction'})
              })
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    rowItem(){
      let app = this
      let data = this.$store.state.auth.prediction
      app.form.id = data.id
      app.form.name = data.name
      app.form.total_odds = data.total_odds
      app.form.starting_date = data.starting_date
      app.form.ending_date = data.ending_date
      app.form.prediction = data.prediction
      app.form.number_of_games = data.number_of_games
    },
  },
  mounted() {
    this.rowItem()
  }
};
</script>
<style scoped>
.l-container-content{
  padding: 1.5em;
}
</style>

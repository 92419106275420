<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col">
        <div class="text-title">
          <h3>Edit User</h3>
        </div>
        <sui-form>
          <sui-form-field>
            <sui-form-fields fields="three">
              <sui-form-field>
                <label>First Name</label>
                <input placeholder="First Name" v-model="form.first_name"/>
              </sui-form-field>
              <sui-form-field>
                <label>Middle Name</label>
                <input placeholder="Middle Name" v-model="form.middle_name"/>
              </sui-form-field>
              <sui-form-field>
                <label>Last Name</label>
                <input placeholder="Last Name" v-model="form.last_name"/>
              </sui-form-field>
            </sui-form-fields>
          </sui-form-field>
          <sui-form-field>
            <label>User Role</label>
            <sui-dropdown
                placeholder="User Role"
                selection
                :options="roles"
                v-model="form.role_id"
            />
          </sui-form-field>
          <sui-button color="blue" fluid size="large" @click.prevent="updateUser"> Update User </sui-button>
        </sui-form>
      </div>
    </div>
  </div>
</template>
<script>
import loader from "@/components/loader";
import {mapActions} from "vuex";
export default {
  data: function() {
    return {
      form: {
        first_name: null,
        last_name: null,
        middle_name: null,
        role_id: null,
        user_id: null
      },
      roles: [],
    };
  },
  components: {
    loader
  },
  methods: {
    ...mapActions(["EditUser", "getRoles"]),
    async updateUser() {
      let app = this

      const payload = {
        'first_name': this.form.first_name,
        'middle_name': this.form.middle_name,
        'last_name': this.form.last_name,
        'role_id': this.form.role_id,
        'user_id': this.form.user_id,
      }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this edits this user!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, edit!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.EditUser(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Updated!', result.value.message, 'success')
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async setRoles() {
      let app = this
      let response = await this.getRoles()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let role = { text: item.acl_name, value: item.acl_id }
          app.roles.push(role)
        })
      }
    },

    resetForm: function () {
      this.form.mobile_number = null
      this.form.email_address = null
      this.form.first_name = null
      this.form.last_name = null
      this.form.middle_name = null
      this.form.role_id = null
      this.form.user_id = null
    },

    user(){
      let app = this
      let data = this.$store.state.auth.user
      app.form.mobile_number = data.mobile_number
      app.form.email_address = data.email_address
      app.form.first_name = data.first_name
      app.form.last_name = data.surname
      app.form.middle_name = data.middle_name
      app.form.role_id = data.acl_id
      app.form.user_id = data.user_id
    },
  },
  mounted() {
    this.setRoles()
    this.user()
  }
};
</script>
<style scoped>
.l-container-content{
  padding: 1.5em;
}
</style>

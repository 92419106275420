<template>
  <div class="l-main bg-white">
    <div class="l-main-content p-left p-right p-top">
      <div class="row">
        <div class="col-6">
          <div class="input inline">
            <input name="dates" placeholder="Choose date" />
          </div>
        </div>
        <div class="col-6 text-right">
          <div class="input inline">
            <input type="text" placeholder="Search..." v-model="filter">
          </div>
        </div>
      </div>
      <div class="row">
        <loader v-show='loading' />
        <div class="overflow">
          <vuetable ref="vuetable"
                    :api-url="url"
                    :fields="columns"
                    pagination-path=""
                    :sort-order="sortOrder"
                    :append-params="moreParams"
                    :per-page="10"
                    :httpOptions="httpOptions"
                    pagination-info-no-data-template="The requested query returned no result"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:loading="onLoading"
                    @vuetable:loaded="onLoaded">
          </vuetable>
        </div>
        <div class="vuetable-pagination ui basic segment grid">
          <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
          <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import loader from "@/components/loader"
import tableCss from "../components/css.js";
import store from "../store";
import accounting from "accounting";

export default {
  components: {
    Vuetable, VuetablePagination, VuetablePaginationInfo,loader
  },
  name: 'purchases',

  data() {
    return {
      url:store.state.auth.api + "transaction/inventory",
      httpOptions: { headers: { AUTH: store.state.auth.token } },
      css: tableCss,
      loading: true,
      selected_data: {},
      columns: [
        {
          name: 'msisdn',
          title: '<span class="orange glyphicon glyphicon-user"></span> Phone No',
          sortField: 'msisdn',
          titleClass: '',
          dataClass: ''
        },
        {
          name: 'email_address',
          title: 'Email Address',
          sortField: 'email_address',
          titleClass: '',
          dataClass: '',

        },
        {
          name: "inventory_name",
          title: 'Item Name',
          sortField: 'inventory_name',
        },
        {
          name: 'price',
          title: 'Price',
          titleClass: '',
          formatter: (value) => accounting.formatNumber(value, 2)
        },
        {
          name: "created",
          title: 'Date',
          sortField: 'created',
          callback: 'createdAt'
        }

      ],
      range:'',
      filter:'',
      loadOnStart: true,
      sortOrder: [{
        field: 'created',
        direction: 'desc'
      }],
      is_partial_resulting: false,

      moreParams: {
        start: '',
        filter: '',
        end: ''
      },

    }
  },


  mounted() {
    //var headerHeight = document.getElementById('header').offsetHeight;
    //document.getElementById('main-content').style.top = headerHeight + 'px';

    var vm = this;

    var start = moment().subtract(1, 'year')
    var end = moment();

    function cb(start, end) {
      vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
      $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
    }

    $('input[name="dates"]').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);

    cb(start, end);

  },

  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.refresh();
    },
    range(){

      var start = this.range[0];
      var end = this.range[1];
      start= moment(start).format('YYYY-MM-DD');
      end= moment(end).format('YYYY-MM-DD');
      this.moreParams.start = start;
      this.moreParams.end = end;
      this.$refs.vuetable.refresh();
    },
    filter: function (val,oldVal) {

      if(val !== oldVal && val.length >= 2) {
        console.log('search: '+val)
        this.searchRecord(val)
      }
    }
  },

  methods: {

    dateFilter: function (start, end) {

      var vm = this;


      vm.start = start;
      vm.end = end;


      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.refresh();

      console.log("start date filter",start)
      console.log("end date filter ",end)
    },
    createdAt: function (value) {
      if (!value) {
        return '-';
      } else {
        return moment(value).format('h:mm a,DD MMM YYYY');
      }

    },

    searchRecord:function(val){
      this.loadRecord = true;
      this.moreParams.filter = val
      this.$refs.vuetable.refresh();
    },

    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },

    onPaginationData(paginationData) {
      this.$refs.paginationInfo.setPaginationData(paginationData)
      this.$refs.pagination.setPaginationData(paginationData)
    },


    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },

    onLoading() {
      this.loading = true
      console.log('loading... show your spinner here')
    },
    onLoaded() {
      this.loading = false
      console.log('loaded! .. hide your spinner here');
      $(".ui.dropdown").dropdown();
    },
  },


}

</script>

<style lang="scss" scoped>
.ui.fixed.table{
  table-layout: auto !important;
}
td{
  white-space: nowrap;
  &.vuetable-slot{
    overflow: visible !important;
  }
}
</style>
<template>
    <div class="l-main">
        <div class="l-container">
            <SubNav />
            <router-view></router-view>
        </div>
    </div>
</template>

<script type="text/javascript">
import SubNav from "@/components/sub-navigation";
export default {
    components: {
        SubNav
    }
}
</script>
<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col">
        <div class="text-title">
          <h3>Add Prediction</h3>
        </div>
        <sui-form>
          <sui-form-fields fields="two">
            <sui-form-field>
              <label>Name</label>
              <input placeholder="Name" v-model="form.name"/>
            </sui-form-field>
            <sui-form-field>
              <label>Category</label>
              <sui-dropdown
                  placeholder="Category"
                  selection
                  :options="categories"
                  v-model="form.category_id"
              />
            </sui-form-field>
          </sui-form-fields>
          <sui-form-field>
            <label>Description</label>
            <input placeholder="Description" v-model="form.description"/>
          </sui-form-field>
          <sui-form-field>
            <label>Comment</label>
            <textarea rows="2" v-model="form.comment"></textarea>
          </sui-form-field>
          <sui-form-fields fields="four">
            <sui-form-field>
              <label>No of Games</label>
              <input placeholder="No of Games" type="number" v-model="form.number_of_games"/>
            </sui-form-field>
            <sui-form-field>
              <label>Total Odds</label>
              <input placeholder="Total Odds" type="number" v-model="form.total_odds"/>
            </sui-form-field>
            <sui-form-field>
              <label>Start Time</label>
              <input placeholder="Start Time" type="datetime-local" v-model="form.starting_date"/>
            </sui-form-field>
            <sui-form-field>
              <label>End Time</label>
              <input placeholder="End Time" type="datetime-local" v-model="form.ending_date"/>
            </sui-form-field>
          </sui-form-fields>
          <sui-form-field>
            <label>Prediction</label>
            <textarea v-model="form.prediction"></textarea>
          </sui-form-field>
          <sui-button color="blue" fluid size="large" @click.prevent="createPrediction"> Add Prediction </sui-button>
        </sui-form>
      </div>
    </div>
  </div>
</template>
<script>
import loader from "@/components/loader";
import {mapActions} from "vuex";
export default {
  data: function() {
    return {
      form: {
        name: null,
        description: null,
        category_id: null,
        comment: null,
        number_of_games: null,
        prediction: null,
        starting_date: null,
        ending_date: null,
        total_odds: null
      },
      roles: [],
      leagues: [],
      categories: []
    };
  },
  components: {
    loader
  },
  methods: {
    ...mapActions(["addCustomPrediction", "getLeagues", "getCategories"]),
    async setLeagues() {
      let app = this
      let response = await this.getLeagues()
      if (response.status === 200){
        //console.log("leagues: " + JSON.stringify(response.message))
        response.message.forEach(function (item) {
          let name = item.league_name + " (" + item.league_type + ") - " + item.country_name;
          let league = { text: name, value: item.league_id }
          app.leagues.push(league)
        })
      }
    },

    async setCategories() {
      let app = this
      let payload = {}
      let response = await this.getCategories(payload)
      if (response.status === 200){
        //console.log("categories: " + JSON.stringify(response.message))
        response.message.forEach(function (item) {
          let name = item.alias_name
          let data = { text: name, value: item.id }
          app.categories.push(data)
        })
      }
    },

    async createPrediction() {
      let app = this

      let payload = this.form

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this adds a new prediction!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.addCustomPrediction(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Added!', result.value.message, 'success')
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    resetForm: function () {
      this.form.name = null
      this.form.description = null
      this.form.category_id = null
      this.form.comment = null
      this.form.number_of_games = null
      this.form.prediction = null
      this.form.starting_date = null
      this.form.ending_date = null
      this.form.total_odds = null
    }
  },
  mounted() {
    this.setLeagues()
    this.setCategories()
  }
};
</script>
<style scoped>
.l-container-content{
  padding: 1.5em;
}
</style>

<template>
  <div class="l-login">
    <div class="l-form">
      <div class="row">
        <div class="col">
          <h2>Login</h2>
          <div v-if="showError">
            <small class="text-red">{{loginStatusMessage}}</small>
          </div>
          <div v-if="showSuccess">
            <small class="text-green">{{loginStatusMessage}}</small>
          </div>
        </div>
        <form @submit.prevent="submit" v-show="active === 'login'">
          <div class="col">
            <label>Phone number</label>
            <input type="tel" v-model="form.username" placeholder="Enter phone number" />
          </div>
          <div class="col">
            <label>Password</label>
            <input type="password" v-model="form.password" placeholder="xxxx" />
          </div>
          <div class="col">
            <button type="submit">Next</button>
          </div>
          <div class="col">
            <a href="javascript:void(0)" @click.prevent="page('forgot')">Forgot password</a>
          </div>
        </form>
        <form @submit.prevent="resetPass" v-show="active === 'reset'">
          <div class="col">
            <label>Reset Code</label>
            <input type="text" v-model="form_new.code" placeholder="1234" />
          </div>
          <div class="col">
            <label>New Password</label>
            <input type="password" v-model="form_new.new_password" placeholder="xxxxxxxx" />
          </div>
          <div class="col">
            <label>Confirm Password</label>
            <input type="password" v-model="form_new.confirm_password" placeholder="xxxxxxxx" />
          </div>
          <div class="col">
            <button type="submit">Next</button>
          </div>
        </form>
        <form @submit.prevent="resetPass" v-show="active === 'new_pass'">
          <div class="col">
            <label>Reset Code</label>
            <input type="text" v-model="form_new.code" placeholder="1234" />
          </div>
          <div class="col">
            <label>New Password</label>
            <input type="password" v-model="form_new.new_password" placeholder="xxxx" />
          </div>
          <div class="col">
            <label>Confirm Password</label>
            <input type="password" v-model="form_new.confirm_password" placeholder="xxxxxxxx" />
          </div>
          <div class="col">
            <button type="submit">Next</button>
          </div>
        </form>
        <form @submit.prevent="forgotPass" v-show="active === 'forgot'">
          <div class="col">
            <label>Phone number</label>
            <input type="tel" v-model="form.username" placeholder="Enter phone number" />
          </div>
          <div class="col">
            <button type="submit">Next</button>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>
<script>
import store from "../store";
import { mapActions } from "vuex";
import $ from 'jquery'
export default {
  name: "Login",
  data:function(){
    return{
      permission: store.state.auth.permission,
      form: {
        username: "",
        password: "",
      },
      form_new: {
        new_password: "",
        confirm_password: "",
        code: "",
      },
      showError: false,
      showSuccess: false,
      loginStatusMessage: '',
      active: 'login'
    }
  },
  methods: {
    ...mapActions(["LogIn", "passwordReset", "passwordForgot"]),
    async submit() {
      let app = this

      const payload = {
        'user_name': this.form.username,
        'user_pass': this.form.password,
        'country_code': '254'
      }

      try {
        let response = await this.LogIn(payload);
        console.log("res: " + JSON.stringify(response))
        if (response.status === 200) {
          if (this.$store.state.auth.acl_id === 6){
            await this.$router.push({name: 'news'})
          }
          else {
            await this.$router.push({name: 'dashboard'})
          }
        }
        else {
          this.loginStatusMessage = response.message
          if (response.status === 401 || response.status === 400) {
            app.showSuccess = false
            app.showError = true
          }
          else {
            app.active = 'reset'
            app.form_new.code = ""
            app.form_new.new_password = ""
            app.showError = true
            app.showSuccess = false
          }
        }
      } catch (error) {
        app.showError = true
      }
    },
    async submit1() {
      await this.$router.push({name: 'dashboard'})
    },

    page(page) {
      this.active = page
    },

    async resetPass() {
      let app = this
      if (this.form_new.new_password !== this.form_new.confirm_password){
        this.showError = true
        this.showSuccess = false
        this.loginStatusMessage = "Passwords do not match"
        return
      }
      const payload = {
        'user_name': this.form.username,
        'new_password': this.form_new.new_password,
        'verification_code': this.form_new.code,
        'country_code': '254'
      }

      try {
        let response = await this.passwordReset(payload);
        if (response.status === 200) {
          this.showSuccess = true
          this.showError = false
          this.loginStatusMessage = response.message
          app.active = 'login'
        }
        else {
          this.showError = true
          this.showSuccess = false
          this.loginStatusMessage = response.message
        }
      } catch (error) {
        this.showError = true
      }
    },

    async forgotPass() {
      let app = this
      const payload = {
        'user_name': this.form.username,
        'country_code': '254'
      }

      try {
        let response = await this.passwordForgot(payload);
        if (response.status === 200) {
          this.showSuccess = true
          this.showError = false
          this.loginStatusMessage = response.message
          this.form_new.code = null
          this.form_new.new_password = null
          this.form_new.confirm_password = null
          app.active = 'reset'
        }
        else {
          this.showError = true
          this.showSuccess = false
          this.loginStatusMessage = response.message
        }
      } catch (error) {
        this.showError = true
      }
    },
  }
}
</script>

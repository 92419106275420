<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col">
        <div class="text-title">
          <h3>Reconcile Payment</h3>
        </div>
        <sui-form>
          <sui-form-field>
            <label>Type</label>
            <sui-dropdown
                placeholder="Type"
                selection
                :options="options"
                v-model="selectedOption"
            />
          </sui-form-field>
          <sui-form-field v-show="selectedOption === 1">
            <label>Identifier Type</label>
            <sui-dropdown
                placeholder="Identifier Type"
                selection
                :options="identifier"
                v-model="form.identifier_type"
            />
          </sui-form-field>
          <sui-form-field v-show="selectedOption === 1">
            <sui-form-field>
              <label>Mpesa Code</label>
              <input placeholder="Mpesa Code" v-model="form.mpesa_code"/>
            </sui-form-field>
          </sui-form-field>
          <sui-form-field v-show="selectedOption === 2">
            <sui-form-field>
              <label>File</label>
              <input type="file" id="image" name="image" placeholder="Enter File" accept="text/csv" required>
            </sui-form-field>
          </sui-form-field>
          <sui-button v-show="selectedOption === 1" color="blue" fluid size="large" @click.prevent="reconcile"> Reconcile </sui-button>
          <sui-button v-show="selectedOption === 2" color="blue" fluid size="large" @click.prevent="reconcileFile"> Reconcile </sui-button>
        </sui-form>
      </div>
    </div>
  </div>
</template>
<script>
import loader from "@/components/loader";
import {mapActions} from "vuex";
import $ from "jquery";
export default {
  data: function() {
    return {
      form: {
        identifier_type: 1,
        mpesa_shortcode: null,
        mpesa_code: null,
        token: null
      },
      identifier: [
        {text: 'Paybill', value: 1}
      ],
      options: [
        {text: 'Single', value: 1},
        {text: 'File', value: 2}
      ],
      selectedOption: 1
    };
  },
  components: {
    loader
  },
  methods: {
    ...mapActions(["Reconcile", "ReconcileFile"]),
    async reconcile() {
      let app = this

      const payload = {
        'mpesa_code': this.form.mpesa_code,
        'identifier_type': this.form.identifier_type
      }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this edits reconciles payment!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reconcile!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.Reconcile(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Queued!', result.value.message, 'success')
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async reconcileFile() {
      let app = this

      const payload = new FormData();
      payload.append('image', $('#image')[0].files[0])

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this edits reconciles payment!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reconcile!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.ReconcileFile(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Queued!', result.value.message, 'success')
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    resetForm: function () {
      this.form.mpesa_code = null
      this.form.identifier_type = 1
    },
  },
  mounted() {
  }
};
</script>
<style scoped>
.l-container-content{
  padding: 1.5em;
}
</style>

<template>
  <div>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col-6">
        <h3>Jackpot Prediction</h3>
      </div>
      <div class="col-6 text-right">
        <router-link :to="{name: 'AddJackpot'}" class="button primary">Add Jackpot</router-link>
      </div>
    </div>
    <div class="row">
      <sui-form>
        <sui-form-field>
          <label>Filter By Name</label>
          <sui-dropdown
              placeholder="Jackpot"
              selection
              :options="jackpots"
              v-model="reference_id"
          />
        </sui-form-field>
      </sui-form>
    </div>
    <div class="row">
      <loader v-show="loading"/>
      <vuetable ref="vuetable"
                :api-mode="false"
                :fields="fields"
                :per-page="perPage"
                track-by="id"
                :data-manager="dataManager"
                pagination-path="pagination"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded"
      >
        <template slot="teams" slot-scope="props">
          {{ props.rowData.home_team }} vs {{ props.rowData.away_team }}
        </template>
        <template slot="actions" slot-scope="props">
          <sui-button size="mini" color="blue" content="Edit" @click="toggle(props.rowData)"/>
        </template>
      </vuetable>
      <div style="padding-top:10px">
        <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
      </div>
    </div>
  </div>

    <!--  Modal | Edit Jackpot-->
    <sui-modal v-model="open">
      <sui-modal-header>Edit Prediction</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <sui-form>
            <sui-form-fields fields="two">
              <sui-form-field>
                <label>Home Team</label>
                <input placeholder="Home Team" type="text" v-model="form.home_team"/>
              </sui-form-field>
              <sui-form-field>
                <label>Away Team</label>
                <input placeholder="Away Team" type="text" v-model="form.away_team"/>
              </sui-form-field>
            </sui-form-fields>
            <sui-form-fields fields="three">
              <sui-form-field>
                <label>Prediction 1</label>
                <input placeholder="Prediction 1" type="text" v-model="form.prediction1"/>
              </sui-form-field>
              <sui-form-field>
                <label>Prediction 2</label>
                <input placeholder="Prediction 2" type="text" v-model="form.prediction2"/>
              </sui-form-field>
              <sui-form-field>
                <label>Game Time</label>
                <input placeholder="Game Time" type="datetime-local" v-model="form.match_time"/>
              </sui-form-field>
            </sui-form-fields>
            <sui-form-fields fields="two">
              <sui-form-field>
                <label>Winning Combination</label>
                <input placeholder="Winning Combination" type="text" v-model="form.winning_combination"/>
              </sui-form-field>
              <sui-form-field>
                <label>Fulltime Score</label>
                <input placeholder="Fulltime Score" type="text" v-model="form.fulltime_score"/>
              </sui-form-field>
            </sui-form-fields>
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click.native="resetForm"> Cancel </sui-button>
        <sui-button color="blue" @click.native="editJackpot"> Update </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import store from "../../store";
import {mapActions} from "vuex";
import axios from "axios";
import _ from "lodash";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "approvals",

  data() {
    return {
      open: false,
      loading: true,
      fields: [
        {
          name: "name",
          title: '<span class="orange glyphicon glyphicon-user"></span> Jackpot Name',
          titleClass: "",
          dataClass: ""
        },
        {
          name: "teams",
          title: "Teams",
          sortField: "home_team",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
        {
          name: "prediction1",
          title: "Prediction 1",
          sortField: "prediction1",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
        {
          name: "prediction2",
          title: "Prediction 2",
          sortField: "prediction2",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
        {
          name: "match_time",
          title: "Match Time",
          sortField: "match_time",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
        {
          name: "winning_combination",
          title: "Winning Combination",
          sortField: "winning_combination",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
        {
          name: "fulltime_score",
          title: "Fulltime Score",
          sortField: "fulltime_score",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
        {
          name: "created",
          title: "Date",
          sortField: "created",
          callback: "createdAt"
        },
        {
          name: "actions",
          title: "Action",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
      ],
      sortOrder: [
        {
          field: "created_at",
          direction: "desc"
        }
      ],
      moreParams: {
        start: "",
        filter: "",
        end: ""
      },
      perPage: 10,
      data: [],
      checkedIds: [],
      jackpots: [],
      form: {
        id: null,
        home_team: null,
        away_team: null,
        prediction1: null,
        prediction2: null,
        match_time: null,
        winning_combination: null,
        fulltime_score: null,
      },
      reference_id: null,
    };
  },

  mounted() {

    var vm = this;

    let payload = {}
    vm.setMatches(payload)
    vm.setJackpots()
  },

  watch: {
    data(newVal, oldVal) {
      this.reload()
    },
    reference_id(newVal, oldVal) {
      if (newVal !== oldVal){
        let payload = {reference_id: this.reference_id}
        this.setMatches(payload)
      }
    }
  },

  methods: {
    ...mapActions(["updateJackpot", "getJackpotMatches", "getJackpots"]),
    toggle(data) {
      this.form.home_team = data.home_team
      this.form.away_team = data.away_team
      this.form.prediction1 = data.prediction1
      this.form.prediction2 = data.prediction2
      this.form.match_time = data.match_time
      this.form.winning_combination = data.winning_combination
      this.form.fulltime_score = data.fulltime_score
      this.form.id = data.id
      this.open = !this.open;
    },
    resetForm() {
      this.form.home_team = null
      this.form.away_team = null
      this.form.prediction1 = null
      this.form.prediction2 = null
      this.form.match_time = null
      this.form.winning_combination = null
      this.form.fulltime_score = null
      this.form.id = null
      this.open = false
    },
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.refresh();

      console.log("start date filter", start);
      console.log("end date filter ", end);
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },

    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
    },

    async setMatches(payload) {
      let response = await this.getJackpotMatches(payload)
      this.data = response.message
    },

    async editJackpot(){
      let app = this
      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this edits this prediction!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          let vm = this
          return await vm.updateJackpot(app.form)
        },
      })
          .then(async (result) => {
            console.log('got here 1: ' + JSON.stringify(result))
            if (result.value.status === 200) {
              app.resetForm()
              await app.reload()
              app.$swal.fire('Updated!', result.value.message, 'success')

            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async setJackpots() {
      let app = this
      let response = await this.getJackpots()
      if (response.status === 200){
        //console.log("leagues: " + JSON.stringify(response.message))
        response.message.forEach(function (item) {
          let data = { text: item.name, value: item.id }
          app.jackpots.push(data)
        })
      }
    },
  }
};
</script>
<style scoped>
td.vuetable-slot {
  overflow: visible !important;
}
.l-container-content{
  padding: 1.5em;
}
</style>

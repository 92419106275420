//store/modules/auth.js
import Vue from 'vue'
import axios from 'axios';
import router from './router'
import moment from "moment";

const state = {
    permission: 3,
    base: process.env.BASE_URL,
    prediction: null,
    article: null,
    api: 'https://sso.sokapro.com/admin/v1/',
    baseApi: 'https://sso.sokapro.com/',
    acl_id: null,
    fixture_id: null,
    profile_info: null,
    appKey: "xj91U3mjQUlIYZXofN",
    tokenKey: null,
    authKey: "ADMIN_APP",
    authKeyWeb: "WEB_APP",
    user: null,
};
const getters = {
    isAuthenticated: state => !!state.token,
    StatePermission: state => state.permission,
    StateToken: state => state.token,
    StateApi: state => state.api,
    BaseApi: state => state.baseApi,
    StateUser: state => state.user,
    StateAppKey: state => state.appKey,
    StateTokenKey: state => state.tokenKey,
    StateAuthKey: state => state.authKey,
    StateAuthKeyWeb: state => state.authKeyWeb,
};
const actions = {
    async LogOut({commit}){
        commit('setLogOut')
    },
    async LogIn({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'login', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
            }})
            .then(async function (response) {
                console.log("success res: "+JSON.stringify(response.data))
                console.log("su00000: 1")
                if (response.data.data.code === 200) {

                    await commit('setAuth', response.data.data.data.token)
                    await commit('setPermission', parseInt(response.data.data.data.acl_id))
                    await commit('setProfileInfo', response.data.data.data.profile_info)

                    res.status = 200
                    res.message = 'Logged in successful'
                }
                else {

                    console.log("su00000: else")
                    res.status = response.data.data.code
                    res.message = response.data.data.message
                    //return res
                }

                console.log("error: " + JSON.stringify(response.data.data))
            })
            .catch(async function (error) {
                res.status = error.response.status
                res.message = error.response.statusText
                console.log('error: ' + JSON.stringify(error.response.data))

                if (res.status === 401 || res.status === 400) {
                    res.message = error.response.data.data.message

                    let user = {
                        id: error.response.data.data.id,
                        code: error.response.data.data.code
                    }
                    await commit('setUser', user)
                }
            })

        return res
    },
    async passwordForgot({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'reset', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data.message

            })
            .catch(function(error) {
                res.status = error.response.status
                res.message = error.response.statusText
            })

        return res
    },
    async passwordReset({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'verify', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))

                res.status = 200
                res.message = response.data.data.message

            })
            .catch(function(error) {
                res.status = error.response.status
                res.message = error.response.data
            })

        return res
    },
    async Stats({commit, getters}, payload) {
        let res = {}
        console.log("start: " + payload.start);
        let url = 'view/stats'
        if (payload.start !== null && payload.end !== null){
            url = url + '?start=' + payload.start + '&end=' + payload.end;
        }
        await axios.get(getters.StateApi + url, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = 200
                res.message = response.data.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getUsers({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'view/system/user', {headers: {
            'X-Authorization-Key': getters.StateAuthKey,
            'X-Requested-With': "XMLHttpRequest",
            'X-App-Key': getters.StateAppKey,
            'X-Token-Key': getters.StateTokenKey,
        }})
            .then(async function (response) {
                res.status = 200
                res.message = response.data.data.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error))
                res.status = error.response.status
                res.message = error.response.data.data
                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getRoles({getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'view/user_role', {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = 200
                res.message = response.data.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error))
                res.status = error.response.status
                res.message = error.response.data.data
            })

        return res
    },
    async Register({commit, getters}, payload) {
        let res = {}
        payload.token = getters.StateTokenKey

        await axios.post(getters.StateApi + 'add/system/user', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getDeposit({commit, getters}, params = null) {
        let res = {}
        let url = new URLSearchParams(params).toString();
        await axios.get(getters.StateApi + 'view/mpesa_deposits?' + url, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = 200
                res.message = response.data.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error))
                res.status = error.response.status
                res.message = error.response.data.data
                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getProfiles({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'view/profiles', {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = 200
                res.message = response.data.data.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error))
                res.status = error.response.status
                res.message = error.response.data.data
                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async searchProfile({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'search/profile', payload,{headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                //console.log("profile: " + JSON.stringify(response.data.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error))
                res.status = error.response.status
                res.message = error.response.data.data
                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getTransactions({commit, getters}, params = null) {
        let res = {}
        let url = new URLSearchParams(params).toString();

        await axios.get(getters.StateApi + 'view/transactions?' + url, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = 200
                res.message = response.data.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error))
                res.status = error.response.status
                res.message = error.response.data.data
                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async fillUser({commit}, payload) {
        console.log("user: "+JSON.stringify(payload))
        await commit('setUser', payload)
        return true
    },
    async EditUser({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'edit/system/user', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data
            })
            .catch(function(error) {
                res.status = error.response.status
                res.message = error.response.data.data
            })

        return res
    },
    async DeleteUser({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'delete/system/user', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                res.status = error.response.status
                res.message = error.response.data.data
            })

        return res
    },
    async ResetPassword({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'reset/system/user', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data
            })
            .catch(function(error) {
                res.status = error.response.status
                res.message = error.response.data.data
            })

        return res
    },
    async Reconcile({commit, getters}, payload) {
        let res = {}
        payload.token = getters.StateTokenKey

        await axios.post(getters.StateApi + 'mpesa/reconcile', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async ReconcileFile({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'mpesa/reconcile', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 422 || res.status === 500)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async sendSms({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'send/bulk', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 422 || res.status === 500)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async allocateBonus({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'allocate/bonus', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async sendSmsFile({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'send/bulk_csv', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 422 || res.status === 500)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getLeagues({getters}) {
        let res = {}
        await axios.get(getters.BaseApi + 'account/v2/view/leagues', {headers: {
                'X-Authorization-Key': getters.StateAuthKeyWeb,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
            }})
            .then(async function (response) {
                res.status = 200
                res.message = response.data.data.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error))
                res.status = error.response.status
                res.message = error.response.data.data
            })

        return res
    },
    async getCategories({commit, getters}, payload) {
        let res = {}
        payload.token = getters.StateTokenKey
        await axios.post(getters.StateApi + 'view/prediction/category', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = 200
                res.message = response.data.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error))
                res.status = error.response.status
                res.message = error.response.data.data
            })

        return res
    },
    async addCustomPrediction({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.StateApi + 'add/custom/predictions', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async editCustomPrediction({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'edit/custom/predictions', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getCustomPredictions({commit, getters}) {
        let res = {}
        let payload = {}
        payload.token = getters.StateTokenKey
        await axios.post(getters.StateApi + 'view/custom/predictions', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = 200
                res.message = response.data.data.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error))
                res.status = error.response.status
                res.message = error.response.data.data
                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getPredictions({commit, getters}, eventId) {
        let res = {}
        let now = moment().format('YYYY-MM-DD');
        await axios.get(getters.BaseApi + 'account/v2/view/events/' + eventId + '?match_status=0&offset=1&export=0&limit=1000&start=' + now, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = 200
                res.message = response.data.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error))
                res.status = error.response.status
                res.message = error.response.data.data
                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async updateGame({commit, getters}, payload) {
        let res = {}
        payload.token = getters.StateTokenKey
        await axios.post(getters.StateApi + 'tag/prediction/' + payload.event_id, payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 422 || res.status === 500)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getJackpots({commit, getters}) {
        let res = {}
        await axios.post(getters.StateApi + 'view/jackpots', {}, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = 200
                res.message = response.data.data.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error))
                res.status = error.response.status
                res.message = error.response.data.data
                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async addJackpot({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.StateApi + 'add/jackpot/predictions', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data.message
                res.id = response.data.data.id
            })
            .catch(function(error) {
                console.log("jp: " + JSON.stringify(error.response))
                res.status = error.response.status
                res.message = error.response.data.statusDescription

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async updateJackpot({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.StateApi + 'edit/jackpot/predictions', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getJackpotMatches({commit, getters}, payload) {
        let res = {}
        payload.token = getters.StateTokenKey
        payload.offset = 1
        payload.limit = 1000
        await axios.post(getters.StateApi + 'view/jackpot/predictions', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = 200
                res.message = response.data.data.data.data
            })
            .catch(function(error) {
                let err = error.response
                console.log(err)
                if (typeof err === 'undefined'){
                    console.log('got und..')
                    res.status = 204
                    res.message = []
                }
                else {
                    res.status = error.response.status
                    res.message = error.response.data.data
                    if (error.response.status === 401){
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async createLink({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.StateApi + 'add/tag/predictions', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async editLink({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.StateApi + 'edit/tag/predictions', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getLink({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.StateApi + 'view/tag/predictions', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log("res"+JSON.stringify(response.data))
                res.status = response.data.data.code
                if (res.status !== 200)
                    res.message = response.data.data.message
                else
                    res.message = response.data.data.data.data
            })
            .catch(function(error) {
                console.log("error: " + JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async fillPrediction({commit}, payload) {
        await commit('setPrediction', payload)
        return true
    },
    async fillFixtureId({commit}, fixture_id) {
        await commit('setFixtureId', fixture_id)
        return true
    },
    async addNewsCategory({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.BaseApi + 'article/v2/category/create', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = response.data.data.code
                if (res.status === 404) res.message = []
                else res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 500)
                    res.message = error.response.data.statusDescription
                else if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async editNewsCategory({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.BaseApi + 'article/v2/category/update', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = response.data.data.code
                if (res.status === 404) res.message = []
                else res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 500)
                    res.message = error.response.data.statusDescription
                else if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getNewsCategory({commit, getters}) {
        let res = {}
        await axios.get(getters.BaseApi + 'article/v2/category/view', {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = response.data.data.code
                if (res.status === 404) res.message = []
                else res.message = response.data.data.data.data
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async addNewsTag({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.BaseApi + 'article/v2/tag/create', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = response.data.data.code
                if (res.status === 404) res.message = []
                else res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 500)
                    res.message = error.response.data.statusDescription
                else if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async editNewsTag({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.BaseApi + 'article/v2/tag/update', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = response.data.data.code
                if (res.status === 404) res.message = []
                else res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 500)
                    res.message = error.response.data.statusDescription
                else if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getNewsTag({commit, getters}) {
        let res = {}
        await axios.get(getters.BaseApi + 'article/v2/tag/view', {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = response.data.data.code
                if (res.status === 404) res.message = []
                else res.message = response.data.data.data.data
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async addAd({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.BaseApi + 'article/v1/ads/create', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = response.data.data.code
                if (res.status === 404) res.message = []
                else res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 500)
                    res.message = error.response.data.statusDescription
                else if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getAdds({commit, getters}, params) {
        let res = {}
        let url = new URLSearchParams(params).toString();
        await axios.get(getters.BaseApi + 'article/v1/ads/view?' + url, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = response.data.data.code
                if (res.status === 404) res.message = []
                else res.message = response.data.data.data.data
            })
            .catch(function(error) {
                console.log("error: " , error.response)
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async editPage({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.BaseApi + 'article/v2/page/update', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = response.data.data.code
                if (res.status === 404) res.message = []
                else res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 500)
                    res.message = error.response.data.statusDescription
                else if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getPages({commit, getters}) {
        let res = {}
        await axios.get(getters.BaseApi + 'article/v2/page/view', {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = response.data.data.code
                if (res.status === 404) res.message = []
                else res.message = response.data.data.data.data
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async addNewsArticle({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.BaseApi + 'article/v2/create', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = response.data.data.code
                if (res.status === 404) res.message = []
                else res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 500)
                    res.message = error.response.data.statusDescription
                else if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async addNewsArticleAws({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.BaseApi + 'article/v3/create', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = 200
                res.message = 'success'
                //res.status = response.data.data.code
                //if (res.status === 404) res.message = []
                //else res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 500)
                    res.message = error.response.data.statusDescription
                else if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async editNewsArticle({commit, getters}, payload) {
        let res = {}

        await axios.post(getters.BaseApi + 'article/v2/update', payload, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = response.data.data.code
                if (res.status === 404) res.message = []
                else res.message = response.data.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.status
                if (res.status === 500)
                    res.message = error.response.data.statusDescription
                else if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async getNewsArticle({commit, getters}, params) {
        let res = {}
        let url = new URLSearchParams(params).toString();
        await axios.get(getters.BaseApi + 'article/v2/view?' + url, {headers: {
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                console.log(JSON.stringify(response.data))
                res.status = response.data.data.code
                if (res.status === 404) res.message = []
                else res.message = response.data.data.data.data
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else
                    res.message = error.response.data.data.message

                if (error.response.status === 401){
                    commit('setLogOut')
                }
            })

        return res
    },
    async fillArticle({commit}, payload) {
        await commit('setArticle', payload)
        return true
    },
};
const mutations = {
    setPrediction(state, prediction){
        state.prediction = prediction
    },
    setArticle(state, article){
        state.article = article
    },
    setUtm(state, utm){
        state.utm = utm
    },
    setAuth(state, token){
        state.tokenKey = token
    },
    setPermission(state, acl_id){
        state.acl_id = acl_id
    },
    setFixtureId(state, fixture_id){
        state.fixture_id = fixture_id
    },
    setProfileInfo(state, profile_info){
        state.profile_info = profile_info
    },
    setLogOut(state){
        state.tokenKey = null
        router.push({name: 'login'});
    },
    setUser(state, user){
        state.user = user
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};

<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col">
        <div class="l-form row">
          <div class="col">
            <sui-form>
              <sui-form-field>
                <label>Customer(s)</label>
                <input placeholder="07123456789,0734343434" type="text" v-model="msisdn"/>
              </sui-form-field>
            </sui-form>
          </div>
          <div class="col">
            <sui-form>
              <sui-form-field>
                <label>Amount</label>
                <input placeholder="50" type="number" v-model="amount"/>
              </sui-form-field>
            </sui-form>
          </div>
          <div class="col">
            <div class="field">
              <label>Message to send</label>
              <div class="ui top attached segment">
                <div class="ui fluid">
                  <textarea id="text" v-model="text" name="w3review" rows="5" style="width: -webkit-fill-available; border: 0"></textarea>
                </div>
              </div>
              <div class="ui bottom attached secondary segment">
                <div class="ui equal width grid">
                  <div class="column"><strong><span v-text="wordCount"></span></strong> character(s)</div>
                  <div class="right aligned column"><strong><span v-text="pageCount"></span></strong> page(s)</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <button class="button primary" @click="submit">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions} from "vuex";
import $ from "jquery";
import {random} from "lodash";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "approvals",

  data() {
    return {
      senderId: 1,
      saveTemplate: false,
      wordCount: 0,
      pageCount: 0,
      amount: 50,
      schedule: null,
      template: '',
      templateMode: "none",
      templates: [],
      time: '',
      senderIds: [],
      text: '',
      blast_name: null,
      msisdn: null,
      shortCode: 'SOKA',
      type: 'DB',
      queryRecipient: 1,
      query: true,
      uniqueId: random(100000, 999999),
      codes: [
        { text: 'SOKA', value: 'SOKA' },
      ]
    };
  },
  watch: {
    text: function (val,oldVal){
      if(val !== oldVal) {
        var count = val.length;
        // Display it as output
        this.wordCount = count;

        var page = count/160;
        this.pageCount = Math.ceil(page);
      }
    },
  },

  methods: {
    ...mapActions(["allocateBonus", "sendSmsFile"]),
    async submit() {
      let app = this
      let res = 'Yes, send!'
      let res1 = 'Doing this allocates the bonus!'

      if (!this.amount){

        app.$swal.fire('Missing!', "Amount is required", 'error')
        return;
      }
      const payload = {
        amount: this.amount,
        message: this.text,
        msisdn: this.msisdn,
      };

      app.$swal.fire({
        title: 'Are you sure?',
        text: res1,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: res,
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.allocateBonus(payload)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.$swal.fire('Success!', result.value.message, 'success')
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },
    async submitFile() {
      let app = this
      let isScheduled = '0'
      let res = 'Yes, send!'
      let res1 = 'Doing this sends this sms!'
      if (this.schedule){
        isScheduled = '1'
        if (!this.time){

          app.$swal.fire('Missing!', "Fill all the required fields", 'error')
          return;
        }
      }

      if (!this.shortCode || !this.text){

        app.$swal.fire('Missing!', "Fill all the required fields", 'error')
        return;
      }

      const payload = new FormData();
      payload.append('shortCode', this.shortCode)
      payload.append('message', this.text)
      payload.append('uniqueId', this.uniqueId)
      payload.append('isScheduled', isScheduled)
      payload.append('time', this.time)
      payload.append('image', $('#image')[0].files[0])

      if( document.getElementById("image").files.length === 0 ){
        app.$swal.fire('Missing!', "File is required", 'error')
        return;
      }

      app.$swal.fire({
        title: 'Are you sure?',
        text: res1,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: res,
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.sendSmsFile(payload)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.$swal.fire('Success!', result.value.message, 'success')
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },
    resetForm(){
      this.text = ''
      this.msisdn = ''
      this.amount = 50
    }
  }
};
</script>
<style scoped>
td.vuetable-slot {
  overflow: visible !important;
}
.l-container-content{
  padding: 1.5em;
}
</style>

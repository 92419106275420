<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col">
        <div class="text-title">
          <h3>Add Prediction Tag</h3>
        </div>
        <sui-form>
          <sui-form-field>
            <label>Select Fixtures</label>
            <sui-dropdown
                placeholder="Select Fixtures"
                selection
                search
                multiple
                :options="games"
                v-model="game_ids"
            />
          </sui-form-field>
          <sui-form-field>
            <label>Sponsor Link</label>
            <input placeholder="Sponsor Link" v-model="form.sponsor_link"/>
          </sui-form-field>
          <sui-form-field>
            <label>Sponsor Image Link</label>
            <input placeholder="Sponsor Image Link" v-model="form.sponsor_image_link"/>
          </sui-form-field>
          <sui-form-fields fields="two">
            <sui-form-field>
              <label>UTM Source</label>
              <input placeholder="UTM Source" v-model="form.utm_source"/>
            </sui-form-field>
            <sui-form-field>
              <label>UTM Campaign</label>
              <input placeholder="UTM Campaign" v-model="form.utm_campaign"/>
            </sui-form-field>
          </sui-form-fields>
          <sui-button color="blue" fluid size="large" @click.prevent="addTags"> Add Tag </sui-button>
        </sui-form>
      </div>
    </div>
  </div>
</template>
<script>
import loader from "@/components/loader";
import {mapActions} from "vuex";
import $ from "jquery";
export default {
  data: function() {
    return {
      form: {
        sponsor_link: null,
        utm_source: null,
        utm_campaign: null,
        sponsor_image_link: null
      },
      games: [],
      game_ids: null
    };
  },
  components: {
    loader
  },
  methods: {
    ...mapActions(["createLink", "getPredictions", "fillFixtureId"]),
    async addTags() {
      let app = this

      if (app.game_ids.length < 0 || !app.form.sponsor_link)
        app.$swal.fire('Missing Fields!', "Please fill all the required fields", 'error')

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this adds tag to the selected games!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          let response
          let vm = this
          for (const id of app.game_ids) {
            let payload = app.form
            payload.fixture_id = id
            response = await vm.createLink(payload)
          }
          return response
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Added!', result.value.message, 'success')
              app.resetForm()
              this.fillFixtureId(null);
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async setPredictions() {
      let app = this
      let response = await this.getPredictions(3)
      app.games = [];
      if (response.status === 200) {
        //console.log("categories: event: "+ eventId +": " + JSON.stringify(response.message))
        response.message.forEach(function (item) {
          let name = item.home_team + ' vs ' + item.away_team
          let data = { text: name, value: item.event_id }
          app.games.push(data)
        })
      }
    },

    resetForm: function () {
      this.form.sponsor_link = null
      this.form.sponsor_image_link = null
      this.form.utm_source = null
      this.form.utm_campaign = null
      this.game_ids = null
    },
  },
  mounted() {
    this.setPredictions()
    if (this.$store.state.auth.fixture_id) {
      this.game_ids = []
      this.game_ids.push(this.$store.state.auth.fixture_id)
    }
  }
};
</script>
<style scoped>
.l-container-content{
  padding: 1.5em;
}
</style>

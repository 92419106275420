import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import auth from './auth'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    rootUrl :  "http://35.195.83.76/bai_app/v1/", 
    key:"",
    username:'',
    startDate:"",
    endDate:"",
  },
  mutations: {
    setUserKey(state,data){
      state.key=data;
    },
    setUsername(state,data){
      state.username=data;
    },
    setStartDate(state,data){
      state.startDate=data;
    },
    setEndDate(state,data){
      state.endDate=data;
    }
  },
  actions: {
  },
  modules: {
    auth
  },
  plugins: [createPersistedState()]
});

<template>
   <div class="l-page">
        <navigation :class="{'show': showNav == true}" />
        <div class="l-content">
            <div class="l-header" id="header">
                <div class="l-header-container">
                    <div class="l-header-menu" @click="showNavFn">
                       <i class="fa fa-bars"></i>
                    </div>
                    <div class="l-header-title">
                        <h3 class="title">{{this.$route.meta.name}}</h3>
                    </div>
                    <div class="l-header-options">
                       <i class="fa fa-th"></i>
                    </div>
                </div>
            </div>
            <router-view id="main-content"></router-view>
        </div>
    </div>
</template>
<script>
import navigation from "@/components/navigation";
import moment from "moment";
export default {
    data(){
        return{
            showNav: false
        }
    },
    components: {
        navigation
    },
    mounted(){
        var headerHeight = document.getElementById('header').offsetHeight;
        document.getElementById('main-content').style.top = headerHeight + 'px';
        // if(!this.$cookies.get("key")){
        //     this.$router.push({
        //     name: 'login'
        //  });
        // }
    },
    methods: {
        showNavFn(){
            this.showNav = true;
        }
    }
}
</script>
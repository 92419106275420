export default {
    table: {
      tableWrapper: '',
      tableHeaderClass: '',
      tableBodyClass: 'vuetable-semantic-no-top',
      tableClass: 'table bordered',
      loadingClass: 'loading',
      ascendingIcon: 'blue chevron up icon',
      descendingIcon: 'blue chevron down icon',
      ascendingClass: 'sorted-asc',
      descendingClass: 'sorted-desc',
      sortableIcon: 'grey sort icon',
      handleIcon: 'grey sidebar icon',
    },
  
    pagination: {
      wrapperClass: 'ui right floated pagination menu',
      activeClass: 'active large',
      disabledClass: 'disabled',
      pageClass: 'item',
      linkClass: 'icon item',
      paginationClass: 'ui bottom attached segment grid',
      paginationInfoClass: 'left floated left aligned six wide column',
      dropdownClass: 'ui search dropdown',
      icons: {
        first: 'angle double left icon',
        prev: 'left chevron icon',
        next: 'right chevron icon',
        last: 'angle double right icon',
      }
    },
  
    paginationInfo: {
      infoClass: 'left floated left aligned six wide column',
    }
  }
<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col">
        <div class="text-title">
          <h3>Update Prediction Events</h3>
        </div>
        <sui-form>
          <sui-form-fields fields="two">
          <sui-form-field>
            <label>From Category</label>
            <sui-dropdown
                placeholder="Category"
                selection
                :options="categories"
                v-model="fetch_category_id"
            />
          </sui-form-field>
          <sui-form-field>
            <label>To Category</label>
            <sui-dropdown
                placeholder="Category"
                selection
                :options="categories"
                v-model="category_id"
            />
          </sui-form-field>
          </sui-form-fields>
          <sui-form-field>
            <label>Select Fixtures</label>
            <sui-dropdown
                placeholder="Select Fixtures"
                selection
                search
                multiple
                :options="games"
                v-model="game_ids"
            />
          </sui-form-field>
          <sui-button color="blue" fluid size="large" @click.prevent="updateEvent"> Update </sui-button>
        </sui-form>
      </div>
    </div>
  </div>
</template>
<script>
import loader from "@/components/loader";
import {mapActions} from "vuex";
import $ from "jquery";
export default {
  data: function() {
    return {
      form: {
        sponsor_link: null,
        utm_source: null,
        utm_campaign: null,
        sponsor_image_link: null,
        category_id: null,
      },
      category_id: null,
      fetch_category_id: null,
      games: [],
      game_ids: null,
      categories: []
    };
  },
  components: {
    loader
  },
  methods: {
    ...mapActions(["updateGame", "getPredictions", "getCategories"]),
    async updateEvent() {
      let app = this

      if (app.game_ids.length < 0 || !app.category_id)
        app.$swal.fire('Missing Fields!', "Please fill all the required fields", 'error')

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this update the selected games!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          let response
          let vm = this
          for (const id of app.game_ids) {
            let payload = {}
            payload.event_id = id
            payload.category_id = app.category_id
            response = await vm.updateGame(payload)
          }
          return response
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Update!', result.value.message, 'success')
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async setCategories() {
      let app = this
      let payload = {}
      let response = await this.getCategories(payload)
      if (response.status === 200){
        //console.log("categories: " + JSON.stringify(response.message))
        response.message.forEach(function (item) {
          let name = item.alias_name
          let data = { text: name, value: item.id }
          app.categories.push(data)
        })
      }
    },

    async setPredictions(eventId) {
      let app = this
      let response = await this.getPredictions(eventId)
      app.games = [];
      if (response.status === 200) {
        //console.log("categories: event: "+ eventId +": " + JSON.stringify(response.message))
        response.message.forEach(function (item) {
          let name = item.home_team + ' vs ' + item.away_team
          let data = { text: name, value: item.event_id }
          app.games.push(data)
        })
      }
    },

    resetForm: function () {
      this.form.sponsor_link = null
      this.form.sponsor_image_link = null
      this.form.utm_source = null
      this.form.utm_campaign = null
      this.game_ids = null
      this.category_id = null
    },
  },
  mounted() {
    this.setPredictions(this.fetch_category_id)
    this.setCategories()
  },
  watch: {
    fetch_category_id (newVal, oldVal){
      this.setPredictions(newVal);
    }
  }
};
</script>
<style scoped>
.l-container-content{
  padding: 1.5em;
}
</style>

<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col-6">
        <h3>All Users</h3>
      </div>
      <div class="col-6 text-right">
        <router-link :to="{name: 'addUser'}" class="button primary">Add User</router-link>
        <sui-button size="mini" color="red" content="Delete" @click="deleteRow"/>
        <sui-button size="mini" color="orange" content="Reset Password" @click="resetPass"/>
      </div>
    </div>
    <div class="row">
      <loader v-show="loading"/>
      <vuetable ref="vuetable"
                :api-mode="false"
                :fields="fields"
                :per-page="perPage"
                track-by="profile_id"
                :data-manager="dataManager"
                pagination-path="pagination"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded"
      >
        <template slot="checkbox" slot-scope="props">
          <sui-checkbox  v-model="checkedIds" id="check" :value="props.rowData.user_id"/>
        </template>
        <template slot="fullName" slot-scope="props">
          {{ props.rowData.first_name }} {{ props.rowData.middle_name }} {{ props.rowData.surname }}
        </template>
        <template slot="role" slot-scope="props">
          <sui-button v-if="parseInt(props.rowData.acl_id) === 1" size="mini" color="green" :content="props.rowData.acl_name" />
          <sui-button v-else-if="parseInt(props.rowData.acl_id) === 2" size="mini" color="blue" :content="props.rowData.acl_name" />
          <sui-button v-else-if="parseInt(props.rowData.acl_id) === 3" size="mini" color="orange" :content="props.rowData.acl_name" />
          <sui-button v-else-if="parseInt(props.rowData.acl_id) === 4" size="mini" color="purple" :content="props.rowData.acl_name" />
          <sui-button v-else-if="parseInt(props.rowData.acl_id) === 5" size="mini" color="pink" :content="props.rowData.acl_name" />
          <sui-button v-else size="mini" color="brown" :content="props.rowData.acl_name" />
        </template>
        <template slot="actions" slot-scope="props">
          <sui-button size="mini" color="blue" content="Edit" @click="editRow(props.rowData)"/>
          <sui-button v-if="parseInt(props.rowData.user_state) === 3" size="mini" color="green" content="Activate" @click="activateRow(props.rowData.user_id)"/>
          <sui-button v-else size="mini" color="red" content="Deactivate" @click="deactivateRow(props.rowData.user_id)"/>
        </template>
      </vuetable>
      <div style="padding-top:10px">
        <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import store from "../../store";
import {mapActions} from "vuex";
import axios from "axios";
import _ from "lodash";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "approvals",

  data() {
    return {
      loading: true,
      fields: [
        {
          name: "checkbox",
          title: '#',
          titleClass: "center aligned",
          dataClass: "center aligned",
          width: "50px"
        },
        {
          name: "fullName",
          title: '<span class="orange glyphicon glyphicon-user"></span> Full Name',
          titleClass: "",
          dataClass: ""
        },
        {
          name: "mobile_number",
          title: "Phone",
          sortField: "mobile_number",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "email_address",
          title: "Email",
          sortField: "email_address",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "role",
          title: "Role",
          sortField: "acl_id",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
        {
          name: "created_at",
          title: "Date",
          sortField: "created_at",
          callback: "createdAt"
        },
        {
          name: "last_successful_login_date",
          title: "Last Login",
          sortField: "last_successful_login_date",
          callback: "createdAt"
        },
        {
          name: "actions",
          title: "Actions",
          sortField: "",
        },
      ],
      sortOrder: [
        {
          field: "created_at",
          direction: "desc"
        }
      ],
      moreParams: {
        start: "",
        filter: "",
        end: ""
      },
      perPage: 10,
      data: [],
      checkedIds: []
    };
  },

  mounted() {
      
    var vm = this;

    vm.setUsers()
  },

  watch: {
    data(newVal, oldVal) {
      this.reload()
    }
  },

  methods: {
    ...mapActions(["DeleteUser", "ResetPassword", "getUsers", "fillUser", "EditUser"]),
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.refresh();

      console.log("start date filter", start);
      console.log("end date filter ", end);
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },

    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
    },

    async setUsers() {
      let response = await this.getUsers()
      this.data = response.message
    },

    async editRow(data) {
      await this.fillUser(data)
      await this.$router.push({name: 'editUser'})
    },

    async deleteRow(){
      let app = this
      // Use sweetalert2
      if (app.checkedIds.length < 1){
        return app.$swal.fire({title: 'Error!', text: 'Please select a user to Delete', icon: 'danger'})
      }
      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this deletes this user!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          let response
          let vm = this
          for (const id of app.checkedIds) {
            let payload = { 'user_id': id }
            response = await vm.DeleteUser(payload)
          }
          return response
        },
      })
          .then(async (result) => {
            console.log('got here 1: ' + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire({
                title: 'Deleted!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                await app.reload()
              })

            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async resetPass(){
      // Use sweetalert2
      let app = this
      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this resets this user's password!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reset!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          let response
          let vm = this
          for (const id of app.checkedIds) {
            let payload = { 'user_id': id }
            response = await vm.ResetPassword(payload)
          }
          return response

        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.$swal.fire({
                title: 'Reset!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                await app.reload()
              })

            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async activateRow(user_id) {
      let app = this

      const payload = {
        'user_id': user_id,
        'status': 1
      }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this activate this user!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, activate!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.EditUser(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Activated!', result.value.message, 'success')
              app.reload()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async deactivateRow(user_id) {
      let app = this

      const payload = {
        'user_id': user_id,
        'status': 3
      }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this deactivate this user!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, deactivate!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.EditUser(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Deactivated!', result.value.message, 'success')
              app.reload()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },
  }
};
</script>
<style scoped>
td.vuetable-slot {
  overflow: visible !important;
}
.l-container-content{
  padding: 1.5em;
}
</style>